import React from 'react';
import './HolidayCalendar.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import AddHolidayModal from '../../components/AddHolidayModal/AddHolidayModal';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function HolidayCalendar() {
	const [cookie, setCookie] = useCookies();
	const [selectedDate, setSelectedDate] = useState();
	const [selectedDateText, setSelectedDateText] = useState("");
	const [addHolidayModalState, setAddHolidayModalState] = useState(false);
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [eventDayArr, setEventDayArr] = useState([]);
	const [dayEventsArr, setDayEventsArr] = useState([]);

	function toggleAddHolidayModalState() {
		if ( addHolidayModalState === true ){
			getEventOfDate(selectedDate);
		}
		setAddHolidayModalState(!addHolidayModalState);
	}

	function getCalendarForMonth(month, year) {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/calendar/get/";
		let options = { params: { month: month, year: year }, headers: { "Authorization": cookie['token'] } }

		axios
			.get(api_endpoint, options)
			.then((response) => {
				let responseMsg = response.data.message;
				let daysArr = response.data.data;
				let eventDaysArrTemp = [];
				for (let i = 0; i < daysArr.length; i++) {
					if (daysArr[i]['events'].length > 0) {
						let eventDateTemp = new Date( daysArr[i]['year'], daysArr[i]['month'], daysArr[i]['date']);
						eventDaysArrTemp.push(eventDateTemp);
					}
				}
				setEventDayArr(JSON.parse(JSON.stringify(eventDaysArrTemp)));
			})
			.catch(error => {
				console.log(error.response.data);
			})
	}

	function getEventOfDate(date) {
		let yyyy = date.getFullYear();
		let mm = date.getMonth() + 1;
		let dd = date.getDate();

		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/event/getbydate/";
		let options = { params: { date: dd, month: mm, year: yyyy }, headers: { "Authorization": cookie['token'] } }
		setShowLoaderScreenFlag(true);
		axios
			.get(api_endpoint, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				setDayEventsArr(response.data.data);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	function deleteEvent( eventId ){
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/event/delete/"+eventId;
		let options = { headers: { "Authorization": cookie['token'] } }
		setShowLoaderScreenFlag(true);
		axios
			.delete(api_endpoint, options)
			.then((response) => {
				let responseMsg = response.data.message;
				getEventOfDate(selectedDate);

			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	function selectDay(value) {
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		setSelectedDate(value);
		setSelectedDateText(formattedDate);
		getEventOfDate(value);
	}

	useEffect(() => {
		getCalendarForMonth(9, 2023);
		selectDay(new Date());
		return () => {
		}
	}, [])

	return (
		<>
			<div id="calendar-page">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{ translateBr("holiday_calendar") }</h1>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<Calendar tileClassName={({ date, view }) => {
										if (eventDayArr.find((x) => {
											let xDate = new Date(x);
											let returnVal = false;
											if ( date.getFullYear() === xDate.getFullYear() && (date.getMonth() + 1) === xDate.getMonth() && date.getDate() === xDate.getDate() ){
												returnVal = true;
											}
											return returnVal
										})) {
											return 'eventDay'
										}
									}}
										className={"calendarContainer"} onChange={setSelectedDate} value={selectedDate} onClickDay={(value, event) => selectDay(value)} locale='pt-PT' />
								</div>
							</div>
							<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<div className='d-flex justify-content-between'>
										<p className='dateText'>{ translateBr("date") } - {selectedDateText}</p>
										<button className='addHolidayButton' onClick={toggleAddHolidayModalState}>{ translateBr("add_holiday") }</button>
									</div>
									<div className='holidayList'>
										{
											dayEventsArr.map((event, index) => {
												return <>
													<div key={index} className='eventCard'>
														<img src="/icons/svg/close-1.svg" onClick={ () => { deleteEvent( event['_id'] ) } }/>
														<p>{event.name}</p>
													</div>
												</>
											})
										}
									</div>
								</div>

							</div>
						</div>
						<br /><br />
					</div>


				</div>
			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
			{
				addHolidayModalState ? <>
					<AddHolidayModal date={selectedDate} selectedDateText={selectedDateText} toggleAddHolidayModalState={toggleAddHolidayModalState} addHolidayModalState={addHolidayModalState} />
				</> : <>
				</>
			}


		</>
	)
}

export default HolidayCalendar
