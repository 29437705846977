import React from 'react';
import './NewRequestCard.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { translateBr } from '../../functions/genericFunctions';

function NewRequestCard(props) {
	const [cookie, setCookie] = useCookies();
	let requestDetails = props.requestDetails;


	function updateRequestState( state ){
		props.setShowLoaderScreenFlag(true);

		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/leave/"+requestDetails['_id'];
		let options = { headers: { "Authorization": cookie['token'] }, params: { 'status': state } }
		let body = {};
		axios
			.post(api_endpoint, body, options)
			.then((response) => {
				props.getLeaveRequests(0);
				props.setShowLoaderScreenFlag(false);

			})
			.catch(error => {
				console.log(error.response.data);
				props.setShowLoaderScreenFlag(false);
			})
	}

	function approveRequest(){
		updateRequestState(1);
	}

	function rejectRequest(){
		updateRequestState(2);
	}
	return (
		<>
			<div className='cardContainer newRequestCard'>
				<div className='row'>
					<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
						<p className='requestEmployeeName'>{ requestDetails['employee_id']['name'] } ( { requestDetails['employee_id']['employee_id'] } ) </p>
						<p>{ translateBr("leave_type") } - { requestDetails['leave_type'] }</p>
						<p>{ translateBr("first_leave_date") } - { requestDetails['first_leave_date'] }</p>
						<p>{ translateBr("last_leave_date") } - { requestDetails['last_leave_date'] }</p>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
						<p>{ translateBr("reason") } - { requestDetails['reason'] }</p>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
						<p>{ translateBr("attached_documents") }</p>
						<div className='d-flex requestFileContainer'>
							{
								requestDetails['documents'].map( (docLink, index) => {
									return <a key={index} href={docLink} target='_blank'><img src="/icons/svg/file.svg" /></a>
								} )
							}
						</div>
						<div className='d-flex requestActionContainer'>
							<button className='approveButton' onClick={approveRequest}>{ translateBr("approve") }</button>
							<button className='rejectButton' onClick={rejectRequest}>{ translateBr("reject") }</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default NewRequestCard
