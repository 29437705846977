import React from 'react';
import './UpdateDeviceModal.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import LoaderScreen from '../LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function UpdateDeviceModal(props) {
	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const [deviceNameInput, setDeviceNameInput] = useState("");
	const [deviceSerialNumberInput, setDeviceSerialNumberInput] = useState("");
	const [wifiSSIDInput, setWifiSSIDInput] = useState("ABCDEFGH");
	const [wifiPasswordInput, setWifiPasswordInput] = useState("123456789");

	function updateDeviceSubmit() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/device/update/"+props.deviceObj['device_id'];
		if (deviceNameInput.length <= 0 || deviceSerialNumberInput.length <= 0 || wifiSSIDInput.length <= 0 || wifiPasswordInput.length <= 0) {
			setApiResponseText("Enter all details");
			return;
		}
		setShowLoaderScreenFlag(true);
		let body = {
			"ssid": wifiSSIDInput,
			"password": wifiPasswordInput,
			"device_name": deviceNameInput,
		}

		let options = { headers: { "Authorization": cookie['token'] } }

		// console.log(body);
		axios
			.post(api_endpoint, JSON.parse(JSON.stringify(body)), options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				setApiResponseText("Device updated Successfully!");
				// console.log(response.data);
				props.getAllDevices();
				setTimeout(() => {
					props.toggleUpdateDeviceModalState();
				}, 500)

			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setApiResponseText("Device update failed!");
			})
	}

	useEffect(() => {
		console.log(props.deviceObj);
		setDeviceNameInput( props.deviceObj['device_name'] );
		setDeviceSerialNumberInput( props.deviceObj['device_sr'] );
		setWifiSSIDInput( props.deviceObj['ssid'] );
		setWifiPasswordInput( props.deviceObj['password'] );
		return () => {
			
		}
	}, [])
	

	return (
		<>
			<div id="updateDeviceModal" className='modalContainer updateDeviceModal'>
				<div className='updateDeviceModalArea'>
					<div className='closeModal' onClick={props.toggleUpdateDeviceModalState}>
						<img src="/icons/svg/close-1.svg" />
					</div>
					<div className='row'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
							<div className='d-flex justify-content-center'>
								<h1>{ translateBr("edit_device") }</h1>
							</div>

						</div>
					</div>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 updateDeviceForm'>

						<div className='row'>
							<div className='col-12'>
								<div className='updateDeviceFormInputContainer'>
									<p>{ translateBr("device_name") }</p>
									<input className='formTextInput' type="text" placeholder={ translateBr("write_here") } value={deviceNameInput} onChange={(e) => setDeviceNameInput(e.target.value)} />
								</div>
							</div>
							<div className='col-12'>
								<div className='updateDeviceFormInputContainer'>
									<p>{ translateBr("device_serial_number") }</p>
									<input className='formTextInput' disabled type="text" placeholder={ translateBr("write_here") } value={deviceSerialNumberInput} onChange={(e) => setDeviceSerialNumberInput(e.target.value)} />
								</div>
							</div>
							{/* <div className='col-12'>
								<div className='updateDeviceFormInputContainer'>
									<p>{ translateBr("wifi_ssid") }</p>
									<input className='formTextInput' type="text" placeholder={ translateBr("write_here") } value={wifiSSIDInput} onChange={(e) => setWifiSSIDInput(e.target.value)} />
								</div>
							</div>
							<div className='col-12'>
								<div className='updateDeviceFormInputContainer'>
									<p>{ translateBr("wifi_password") }</p>
									<input className='formTextInput' type="text" placeholder={ translateBr("minimum_8_character") } value={wifiPasswordInput} onChange={(e) => setWifiPasswordInput(e.target.value)} />
								</div>
							</div> */}
							<div className='col-12'>
								<p>{apiResponseText}</p>
							</div>
						</div>


						<div className='d-flex justify-content-end updateDeviceFormActionContainer'>
							<button className='cancelButton' onClick={props.toggleUpdateDeviceModalState}>{ translateBr("cancel") }</button>
							<button className='saveButton' onClick={updateDeviceSubmit}>{ translateBr("save") }</button>
						</div>
					</div>
				</div>
			</div>

			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default UpdateDeviceModal
