import translationData from '../json/translate.json';

export const redirectTo = (path) => {
  window.location.href = path;
  return;
};

export function translateBr( key ){
	try {
		return translationData[key]['br'];
	} catch (e) {
		return "";
	}
}

export function translateEn( key ){
	console.log(translationData);
	try {
		return translationData[key]['en'];
	} catch (e) {
		return "";
	}
}

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
};

export const formatDate2 = (date) => {
	let value = new Date(date);
	let yyyy = value.getFullYear();
	let mm = value.getMonth() + 1;
	let dd = value.getDate();
	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;
	let formattedDate = yyyy + '-' + mm + '-' + dd;
	return formattedDate;
}

export function numSeparator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}

export function sliderBackground(filledColor = "#38455C", trackColor = "#E1E9FA", min = 500, max = 600, val = 550) {
  return `linear-gradient(${filledColor}, ${trackColor}) 0/ calc(.5*1.5em + ${((val-min)/(max-min))*100}% - 1.5em) 100% no-repeat ${trackColor}`
}

export function vwTopx(vw){
  return (vw*window.innerWidth)/100;
}

export function fullScreen(){
  if(document.fullscreenElement==null){
      var elem = document.getElementById("root");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }
}
/// Pad any number with zeroes
export function pad(num, size) {
	num = num.toString();
	while (num.length < size) num = "0" + num;
	return num;
}

/// Convert 12 Hour Time Format ( 6:00:05 PM ) to 24 Hours Format ( 18:00:05 )
export function convert12HourTimeTo24HourFormat( timeString ){
	let d = new Date("1/1/2013 " + timeString);
	let hour = pad(d.getHours(), 2);
	let minutes = pad(d.getMinutes(), 2);
	let seconds = pad(d.getSeconds(), 2);
	timeString = hour+":"+minutes+":"+seconds;
	return timeString;
}