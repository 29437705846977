import React from 'react';
import './Devices.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import AddDeviceModal from '../../components/AddDeviceModal/AddDeviceModal';
import UpdateDeviceModal from '../../components/UpdateDeviceModal/UpdateDeviceModal';
import { translateBr } from '../../functions/genericFunctions';


function Devices() {
	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const [allDevicesArr, setAllDevicesArr] = useState([]);
	const [addDeviceFlag, setAddDeviceFlag] = useState(false);
	const [updateDeviceFlag, setUpdateDeviceFlag] = useState(false);
	const [selectedDeviceObj, setSelectedDeviceObj] = useState({});

	function toggleAddDeviceModalState() {
		setAddDeviceFlag(!addDeviceFlag);
	}

	function toggleUpdateDeviceModalState() {
		setUpdateDeviceFlag(!updateDeviceFlag);
	}

	function getAllDevices() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/device/get/";
		setShowLoaderScreenFlag(true);

		let options = { headers: { "Authorization": cookie['token'] } }

		axios
			.get(api_endpoint, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				setAllDevicesArr(JSON.parse(JSON.stringify(response.data.data)));
			})
			.catch(error => {
				setShowLoaderScreenFlag(false);
			})
	}


	function updateDevice(device) {
		setSelectedDeviceObj(device);
		setUpdateDeviceFlag(true);
	}

	useEffect(() => {
		getAllDevices();

		return () => {

		}
	}, [])


	return (
		<>
			<div id="devicesPage">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{ translateBr("devices") }</h1>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<div className='row'>
										<div className='col-12'>
											<div className='d-flex justify-content-end'>
												<button className='registerDeviceButton' onClick={() => { setAddDeviceFlag(true) }}>{ translateBr("register_device") }</button>
											</div>
										</div>

									</div>
									<div className='row'>
										<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
											<div className='d-flex justify-content-center'>
												<div className='devicesTableContainer'>
													{
														allDevicesArr.length > 0 ? <>
															{
																allDevicesArr.map((device, index) => {
																	return (
																		<div key={index} className='row deviceRow' onClick={(e) => { updateDevice(device); }}>
																			<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
																				<p className='deviceName'>{device['device_name']}</p>
																			</div>
																			<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
																				<p className='deviceId'>{device['device_sr']}</p>
																			</div>
																		</div>
																	)
																})
															}
														</> : <>
														</>
													}
												</div>
											</div>

										</div>
									</div>

								</div>
							</div>
						</div>
						<br /><br />
					</div>
				</div>
			</div>
			{
				addDeviceFlag ? <>
					<AddDeviceModal toggleAddDeviceModalState={toggleAddDeviceModalState} getAllDevices={getAllDevices} />
				</> : <>
				</>
			}

			{
				updateDeviceFlag ? <>
					<UpdateDeviceModal toggleUpdateDeviceModalState={toggleUpdateDeviceModalState} getAllDevices={getAllDevices} deviceObj={selectedDeviceObj} />
				</> : <>
				</>
			}
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default Devices
