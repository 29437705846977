import React from 'react';
import './CompanyRegister.css';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Navbar from '../../components/Navbar/navbar';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function CompanyRegister() {
	let navigate = useNavigate();
	const [cookie, setCookie] = useCookies();
	const [companyDetails, setCompanyDetails] = useState({
		"login_id": "",
		"password": "",
		"name": "",
		"address": "",
		"email": "",
		"country_code": "+55",
		"phone_number": "",
		"company_registration_number": "",
		"website": ""
	})
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	function updateCompanyDetails(key, value) {
		let companyDetailsTemp = JSON.parse(JSON.stringify(companyDetails));
		companyDetailsTemp[key] = value;
		setCompanyDetails(JSON.parse(JSON.stringify(companyDetailsTemp)));
	}

	function registerCompanySubmit() {
		console.log(companyDetails);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/company/register";
		setShowLoaderScreenFlag(true);
		let validateDetails = validateCompanyDetails();
		if (validateDetails[0] === 0) {
			setShowLoaderScreenFlag(false);
			setApiResponseText(validateDetails[1]);
			return;
		}

		axios
			.post(api_endpoint, JSON.parse(JSON.stringify(companyDetails)))
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				// setApiResponseText("Company registered, redirecting to login page!");
				setApiResponseText(translateBr("company_registered,_redirecting_to_login_page!"));
				setTimeout(() => {
					navigate("/login");
				}, 2000);
				console.log(response.data.message);

			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setApiResponseText(translateBr("company_registration_failed"));
			})
	}

	function validateCompanyDetails() {
		if (companyDetails['login_id'] === "" || companyDetails['login_id'] === null) {
			return [0, translateBr("enter_login_id")];
		}
		else if (companyDetails['password'] === "" || companyDetails['password'] === null) {
			return [0, translateBr("enter_valid_password")];
		}
		else if (companyDetails['name'] === "" || companyDetails['name'] === null) {
			return [0, translateBr("enter_company_name")];
		}
		else if (companyDetails['company_registration_number'] === "" || companyDetails['company_registration_number'] === null) {
			return [0, translateBr("enter_company_registration_number")];
		}
		else if (companyDetails['email'] === "" || companyDetails['email'] === null) {
			return [0, translateBr("enter_email")];
		}
		else if (companyDetails['phone_number'] === "" || companyDetails['phone_number'] === null) {
			return [0, translateBr("enter_phone_number")];
		}
		else if (companyDetails['address'] === "" || companyDetails['address'] === null) {
			return [0, translateBr("enter_address")];
		}
		else if (companyDetails['website'] === "" || companyDetails['website'] === null) {
			return [0, translateBr("enter_website_url")];
		}
		return [1, translateBr("company_details_validated")];
	}

	useEffect(() => {
		if ( cookie['token'] && cookie['token'] != null && cookie['token'] != "" ){
			// console.log(cookie['token']);
			// navigate("/home");
		}
	
		return () => {
			
		}
	}, [])

	return (
		<>
			<div id="company-register-page">

				<div className="container-fluid">
					<div className='row'>
						<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
							<div className='logoContainer'>
								<img src="/icons/svg/ideiacard-logo.svg" />
							</div>
						</div>
						<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
							<div className='cardContainer companyRegisterFormInputContainer'>
								<h1>{translateBr("register_company")}</h1>
								<div className=' companyRegisterFormInput'>
									<input type='text' placeholder={translateBr("login_id")} value={companyDetails['login_id']} onChange={(e) => updateCompanyDetails('login_id', e.target.value)} />
								</div>
								<div className=' companyRegisterFormInput'>
									<input type='password' placeholder={translateBr("password")} value={companyDetails['password']} onChange={(e) => updateCompanyDetails('password', e.target.value)} />
								</div>
								<div className=' companyRegisterFormInput'>
									<input type='text' placeholder={translateBr("company_name")} value={companyDetails['name']} onChange={(e) => updateCompanyDetails('name', e.target.value)} />
								</div>
								<div className=' companyRegisterFormInput'>
									<input type='text' placeholder={translateBr("company_registration_number")} value={companyDetails['company_registration_number']} onChange={(e) => updateCompanyDetails('company_registration_number', e.target.value)} />
								</div>
								<div className=' companyRegisterFormInput'>
									<input type='text' placeholder={translateBr("business_email")} value={companyDetails['email']} onChange={(e) => updateCompanyDetails('email', e.target.value)} />
								</div>
								<div className=' companyRegisterFormInput'>
									<input type='text' placeholder={translateBr("business_phone")} value={companyDetails['phone_number']} onChange={(e) => updateCompanyDetails('phone_number', e.target.value)} />
								</div>
								<div className=' companyRegisterFormInput'>
									<input type='text' placeholder={translateBr("address")} value={companyDetails['address']} onChange={(e) => updateCompanyDetails('address', e.target.value)} />
								</div>
								<div className=' companyRegisterFormInput'>
									<input type='text' placeholder={translateBr("website_url")} value={companyDetails['website']} onChange={(e) => updateCompanyDetails('website', e.target.value)} />
								</div>

								<br /><br />
								<p className='apiResponseText'>{apiResponseText}</p>
								<div className='row'>
									<div className='col-12'>
										<button className='submitButton' onClick={registerCompanySubmit}>{translateBr("submit")}</button>
									</div>
								</div>
								<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
								<br /><br />

							</div>
						</div>
					</div>
					<br /><br />
					<br /><br />

				</div>
			</div>
		</>
	)
}

export default CompanyRegister
