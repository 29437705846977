import React, { useEffect, useState } from 'react';
import './navbar.css';
import NavbarOption from '../NavbarOption/NavbarOption';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';


function Navbar(props) {
	const [cookie, setCookie, removeCookie] = useCookies();

	const navbarOptionsArr = [{
		id: 0,
		active: true,
		title: window.$translate["home"]["br"],
		iconPath: "/icons/svg/home_1.svg",
		link: "/home"
	}, {
		id: 1,
		active: false,
		title: window.$translate["employees"]["br"],
		iconPath: "/icons/svg/users-1.svg",
		link: "/employees"
	}, {
		id: 2,
		active: false,
		title: window.$translate["timesheet"]["br"],
		iconPath: "/icons/svg/clock-1.svg",
		link: "/timesheet"
	}, /* {
		id: 3,
		active: false,
		title: "Salary Report",
		iconPath: "/icons/svg/brl-icon.svg",
		link: "/salary-report"
	}, */ {
		id: 4,
		active: false,
		title: window.$translate["leave_requests"]["br"],
		iconPath: "/icons/svg/chat-text-1.svg",
		link: "/requests"
	}, {
		id: 5,
		active: false,
		title: window.$translate["calendar"]["br"],
		iconPath: "/icons/svg/calendar-2.svg",
		link: "/calendar"
	}, {
		id: 6,
		active: false,
		title: window.$translate["shifts"]["br"],
		iconPath: "/icons/svg/users-1.svg",
		link: "/shifts"
	}, {
		id: 7,
		active: false,
		title: window.$translate["account"]["br"],
		iconPath: "/icons/svg/user-1.svg",
		link: "/account"
	}, {
		id: 8,
		active: false,
		title: window.$translate["devices"]["br"],
		iconPath: "/icons/png/device.png",
		link: "/devices"
	}, {
		id: 9,
		active: false,
		title: window.$translate["reports"]["br"],
		iconPath: "/icons/svg/file-yellow.svg",
		link: "/reports"
	}, {
		id: 10,
		active: false,
		title: window.$translate["logout"]["br"],
		iconPath: "/icons/svg/logout.svg",
		link: "/logout"
	}];

	const navigate = useNavigate();
	const [navbarOptionsData, setNavbarOptionsData] = useState(navbarOptionsArr);

	function logout() {
		removeCookie("token");
		navigate("/login");
	}

	function navigateFunction(id, link) {
		if (id === 10) {
			logout();
			return;
		}

		let optionTemp = JSON.parse(JSON.stringify(navbarOptionsData));
		for (let i = 0; i < optionTemp.length; i++) {
			if (optionTemp[i]['id'] === id) {
				optionTemp[i]['active'] = true;
			}
			else {
				optionTemp[i]['active'] = false;
			}
		}
		setNavbarOptionsData(JSON.parse(JSON.stringify(optionTemp)));
		toggleMobileNavbar();
		navigate(link);
	}

	function toggleDesktopNavbar() {
		props.setDesktopNavbarState(!props.desktopNavbarState);
	}

	function toggleMobileNavbar() {
		props.setMobileNavbarState(!props.mobileNavbarState);
	}

	useEffect(() => {
		let currentPathName = window.location.pathname;
		let optionTemp = JSON.parse(JSON.stringify(navbarOptionsData));
		for (let i = 0; i < optionTemp.length; i++) {
			if (optionTemp[i]['link'] === currentPathName) {
				optionTemp[i]['active'] = true;
			}
			else {
				optionTemp[i]['active'] = false;
			}
		}
		setNavbarOptionsData(JSON.parse(JSON.stringify(optionTemp)));
		return () => {

		}
	}, [])

	useEffect(() => {
		if (cookie['token'] && cookie['token'] != null && cookie['token'] != "") {
			console.log(cookie['token']);
			// navigate("/home");
		}
		else {
			navigate("/login");
		}

		return () => {

		}
	}, [])


	return (
		<>
			<div className='mobileNavbarButtonContainer'>
				<div className='mobileNavbarButton' onClick={toggleMobileNavbar}>
					<img src="/icons/png/ideiacard-icon.png" />
				</div>
			</div>
			{
				props.desktopNavbarState ? <>
					<div className='navbarContainer'>
						<div className={`DesktopNavbarArea`}>
							<div className='navbarLogoContainer'>
								<div className='row'>
									<div className='col-9'>
										<div className='menuLogoContainer'>
											<img src="/icons/svg/ideiacard-logo.svg" />
										</div>

									</div>
									<div className='col-3'>
										<div className='collapseButtonArea' onClick={toggleDesktopNavbar}>
											<img src="/icons/svg/chevron-left-1.svg" />
										</div>
									</div>
								</div>
							</div>
							{
								navbarOptionsData.map((option, index) => {
									return (
										<NavbarOption key={index} type={"desktop"} desktopNavbarState={props.desktopNavbarState} id={option['id']} active={option['active']} title={option['title']} iconPath={option['iconPath']} link={option['link']} navigateFunction={navigateFunction} onClick={navigateFunction} />
									)
								})
							}
							<br />
							<br />
						</div>

					</div>

				</> : <>
					<div className='collapsedNavbarContainer'>
						<div className={`DesktopCollapseNavbarArea`}>
							<div className='menuExpandButtonArea' onClick={toggleDesktopNavbar}>
								<img src="/icons/svg/chevron-right-1.svg" />
							</div>
							<div className='row'>
								<div className='col-12'>

									<div className='d-flex justify-content-between menuLogoContainer'>
										<img className='desktopCollapseNavbarMenuLogo' src="/icons/svg/ideiacard-icon.svg" />
									</div>

								</div>
							</div>
							{
								navbarOptionsData.map((option, index) => {
									return (
										<NavbarOption key={index} type={"desktop"} desktopNavbarState={props.desktopNavbarState} id={option['id']} active={option['active']} title={option['title']} iconPath={option['iconPath']} link={option['link']} navigateFunction={navigateFunction} onClick={navigateFunction} />
									)
								})
							}
							<br />
							<br />
						</div>
					</div>

				</>
			}

			{
				props.mobileNavbarState ? <>
					<div className='mobileNavbarContainer'>
						<div className={`MobileNavbarArea`}>
							<div className='row'>
								<div className='col-12'>
									<div className='d-flex justify-content-between mobileMenuLogoContainer'>
										<img className='mobileMenuLogo' src="/icons/svg/ideiacard-icon.svg" />
										<img onClick={toggleMobileNavbar} className='closeMobileMenuButton' src="/icons/svg/close-1.svg" />
									</div>

								</div>

							</div>
							{
								navbarOptionsData.map((option, index) => {
									return (
										<NavbarOption key={index} type={"mobile"} desktopNavbarState={props.desktopNavbarState} id={option['id']} active={option['active']} title={option['title']} iconPath={option['iconPath']} link={option['link']} navigateFunction={navigateFunction} onClick={navigateFunction} />
									)
								})
							}
							<br />
							<br />
						</div>
					</div>

				</> : <>
				</>
			}
		</>
	)
}

export default Navbar
