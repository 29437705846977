import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { useNavigate } from 'react-router-dom';
import { translateBr } from '../../functions/genericFunctions';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import EmployeesClockedInList from '../../components/EmployeesClockedInList/EmployeesClockedInList';
import EmployeesClockedOutList from '../../components/EmployeesClockedOutList/EmployeesClockedOutList';
import EmployeeOnLeaveList from '../../components/EmployeeOnLeaveList/EmployeeOnLeaveList';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';


registerLocale('pt', pt);

function Home() {
	const [cookie, setCookie] = useCookies();
	let navigate = useNavigate();

	const [chartData, setChartData] = useState([
		// ["Type", "Count"],
		// [translateBr("clocked_in"), 11],
		// [translateBr("clocked_out"), 2],
		// [translateBr("on_leave"), 2],
	]);

	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [tabsData, setTabsdata] = useState([{ id: "clocked_in", title: translateBr("clocked_in"), active: true }, { id: "clocked_out", title: translateBr("clocked_out"), active: false }, { id: "on_leave", title: translateBr("on_leave"), active: false }]);
	const [activeTabId, setActiveTabId] = useState("clocked_in");
	const [todayDateStr, setTodayDateStr] = useState("");

	const [reportMonth, setReportMonth] = useState(new Date());

	const options = {
		title: translateBr("today's_attendance_chart"),
		titleTextStyle: {
			color: "#555555",
			fontSize: 20,
		},
		backgroundColor: "white",
		colors: ["#CA498C", "#E6BFCE", "#E854A1", "#FFD600"],
		legend: {
			position: "bottom",
		},
		legendTextStyle: {
			fontSize: 18
		},
		chartArea: {
			height: "100%",
			width: "100%",
			top: 50,
			bottom: 100
		},

	};

	function changeTab(tab_id) {
		let tempTabsData = JSON.parse(JSON.stringify(tabsData));
		for (let i = 0; i < tempTabsData.length; i++) {
			if (tempTabsData[i]['id'] === tab_id) {
				tempTabsData[i]['active'] = true;
			}
			else {
				tempTabsData[i]['active'] = false;
			}
		}
		setTabsdata(JSON.parse(JSON.stringify(tempTabsData)));
		setActiveTabId(tab_id);
	}

	function formatDate(date) {
		let value = new Date(date);
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		return formattedDate;
	}

	function downloadReport() {
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/report/generate/";
		let dateValue = new Date(reportMonth);
		let year = dateValue.getFullYear();
		let month = dateValue.getMonth() + 1; // Months start at 0!
		// let month = '09';
		// let year = '2023';
		console.log(reportMonth);
		let options = { params: { month: month, year: year }, headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				console.log(response.data);
				const href = URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'report.csv'); //or any other extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(href);

				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})
	}

	function getDailyAttedanceMetric(dateString) {
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/attendance/stats/" + dateString;
		console.log(api_endpoint);
		let options = { headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				console.log(response.data.data);
				let metricData = JSON.parse(JSON.stringify(response.data.data));
				let chartDataTemp = [
					["Type", "Count"],
					[translateBr("clocked_in"), metricData['clocked_in_Employees']['total']],
					[translateBr("clocked_out"), metricData['clocked_out_Employees']['total']],
					[translateBr("on_leave"), metricData['on_leave_Employees']['total']],
					[translateBr("unknown"), metricData['unknown']['total']],
				];
				console.log(chartDataTemp);
				setChartData(JSON.parse(JSON.stringify(chartDataTemp)));
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})
	}

	useEffect(() => {
		let todayDateTemp = formatDate((new Date()).toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));
		setTodayDateStr(todayDateTemp);
		getDailyAttedanceMetric(todayDateTemp);
		return () => {
		}
	}, [])


	return (
		<>
			<div id="home-page">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{translateBr("home")}</h1>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<div className='tabsContainer'>
										{
											tabsData.map((tabData, index) => {
												return (
													<div key={index} className={tabData.active === true ? "tab activeTab" : "tab"} onClick={() => { changeTab(tabData.id) }}>
														<p>{tabData.title}</p>
													</div>
												)
											})
										}
									</div>
									<div className='employeesClockInList'>

										{
											activeTabId === "clocked_in" ? <>
												<EmployeesClockedInList date={todayDateStr} />
											</> : activeTabId === "clocked_out" ? <>
												<EmployeesClockedOutList date={todayDateStr} />
											</> : activeTabId === "on_leave" ? <>
												<EmployeeOnLeaveList date={todayDateStr} />
											</> : <></>
										}


									</div>
								</div>
							</div>
							<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<div className='downloadReportContainer'>
										<div className='downloadReportForm'>
											<DatePicker wrapperClassName="monthYearPicker" dateFormat="MMMM yyyy" showMonthYearPicker locale="pt" selected={reportMonth} onChange={(date) => setReportMonth(date)} />
											<button onClick={downloadReport}>{translateBr("download_reports")}</button>
										</div>
									</div>
									<div className='chartContainer'>
										{
											chartData.length > 0 ? <>
												<Chart
													chartType="PieChart"
													data={chartData}
													options={options}
													className={"attendancePieChart"}
												/>
											</> : <>
											</>
										}

									</div>
								</div>
							</div>


						</div>
						<br /><br />
					</div>


				</div>
			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default Home
