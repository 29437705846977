import React from 'react';
import './Shifts.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import AddShiftModal from '../../components/AddShiftModal/AddShiftModal';
import UpdateShiftModal from '../../components/UpdateShiftModal/UpdateShiftModal';
import { translateBr } from '../../functions/genericFunctions';

function Shifts() {
	const [cookie, setCookie] = useCookies();
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [AddShiftModalState, setAddShiftModalState] = useState(false);
	const [updateShiftModalState, setUpdateShiftModalState] = useState(false);
	const [updateShiftDetails, setUpdateShiftDetails] = useState({});
	const [shiftsArr, setShiftsArr] = useState([]);

	function toggleAddShiftModalState() {
		setAddShiftModalState(!AddShiftModalState);
	}

	function getAllShifts() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/shift/getall";

		setShowLoaderScreenFlag(true);

		let options = { headers: { "Authorization": cookie['token'] } }

		axios
			.get(api_endpoint, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				// console.log(response.data.data);
				setShiftsArr(response.data.data);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	function convertDayIndexArrToStr(dayIndexArr) {
		let shiftDaysString = "";
		for (let i = 0; i < dayIndexArr.length; i++) {
			if ( i>0 ){
				shiftDaysString = shiftDaysString + ", ";
			}

			if ( dayIndexArr[i] === 0 ) {
				shiftDaysString = shiftDaysString + translateBr("mon");
			}
			else if ( dayIndexArr[i] === 1 ){
				shiftDaysString = shiftDaysString + translateBr("tue");
			}
			else if ( dayIndexArr[i] === 2 ){
				shiftDaysString = shiftDaysString + translateBr("wed");
			}
			else if ( dayIndexArr[i] === 3 ){
				shiftDaysString = shiftDaysString + translateBr("thu");
			}
			else if ( dayIndexArr[i] === 4 ){
				shiftDaysString = shiftDaysString + translateBr("fri");
			}
			else if ( dayIndexArr[i] === 5 ){
				shiftDaysString = shiftDaysString + translateBr("sat");
			}
			else if ( dayIndexArr[i] === 6 ){
				shiftDaysString = shiftDaysString + translateBr("sun");
			}
		}
		return shiftDaysString;
	}

	useEffect(() => {
		getAllShifts();

		return () => {

		}
	}, [AddShiftModalState])

	function openUpdateShiftModal( shiftDetails ){
		console.log("Update Shift Modal Open");
		setUpdateShiftDetails( shiftDetails );
		setUpdateShiftModalState(true);
	}

	function toggleUpdateShiftModalState() {
		setUpdateShiftModalState(!updateShiftModalState);
	}

	return (
		<>
			<div id="requests-page">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{ translateBr("shifts") }</h1>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
								<div className='cardContainer shiftTableContainer'>
									<div className='row'>
										<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
											<div className='d-flex justify-content-end'>
												<div className='addShiftButtonContainer'>
													<button className='addShiftButton' onClick={toggleAddShiftModalState}>{ translateBr("add_shift") }</button>
												</div>

											</div>
											<div className='shiftsTable'>
												<table>
													<thead>
														<tr>
															<th>{ translateBr("shift_name") }</th>
															<th>{ translateBr("start-time") }</th>
															<th>{ translateBr("shift_interval_start_time") }</th>
															<th>{ translateBr("shift_interval_end_time") }</th>
															<th>{ translateBr("end-time") }</th>
															<th>{ translateBr("total_hours") }</th>
															<th>{ translateBr("days") }</th>
														</tr>
													</thead>
													<tbody>
														{
															shiftsArr.map((shift, index) => {
																return (
																	<tr key={index} onClick={ () => openUpdateShiftModal(shift)}>
																		<td>{shift['name']}</td>
																		<td>{shift['start_time']}</td>
																		<td>{shift['interval_start']}</td>
																		<td>{shift['interval_end']}</td>
																		<td>{shift['end_time']}</td>
																		<td>{shift['total_hours']}</td>
																		<td>{convertDayIndexArrToStr(shift['days_of_week'])}</td>
																	</tr>
																)
															})
														}
													</tbody>
												</table>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						<br /><br />
					</div>
				</div>
			</div>
			<AddShiftModal AddShiftModalState={AddShiftModalState} toggleAddShiftModalState={toggleAddShiftModalState} />
			{
				updateShiftModalState ? <>
					<UpdateShiftModal getAllShifts={getAllShifts} updateShiftModalState={updateShiftModalState} toggleUpdateShiftModalState={toggleUpdateShiftModalState} updateShiftDetails={ updateShiftDetails } />
				</> : <></>
			}
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default Shifts
