import React from 'react';
import './AddEmployeeModal.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

registerLocale('pt', pt);

function AddEmployeeModal(props) {
	const inputProfilePhotoFileRef = React.useRef();
	const profilePhotoRef = React.useRef();
	const salaryInputRef = React.useRef();
	const phoneNumberInputRef = React.useRef();
	const emergencyPhoneNumberInputRef = React.useRef();
	const [salaryInputActiveFlag, setSalaryInputActiveFlag] = useState(false);
	const [phoneNumberInputActiveFlag, setPhoneNumberInputActiveFlag] = useState(false);
	const [emergencyPhoneNumberInputActiveFlag, setEmergencyPhoneNumberInputActiveFlag] = useState(false);
	const [ dateOfBirthInput, setDateOfBirthInput ] = useState( new Date() );
	const [ dateOfEmploymentInput, setDateOfEmploymentInput ] = useState( new Date() );

	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [allShiftsArr, setAllShiftsArr] = useState([]);
	const [employeeFormData, setEmployeeFormData] = useState({
		'login_id': '',
		'password': '',
		'name': '',
		'rg_number': '',
		'cpf_number': '',
		'civil_status': 1,
		'address': '',
		'country_code': '+55',
		'phone_number': '',
		'emergency_contact_person': '',
		'emergency_contact_number': '',
		'number_of_children': '',
		'employment_date': '',
		'ct_number': '',
		'notes': '',
		'remote_employee': false,
		'shift_id': '',
		'contract_id': '-',
		'team_id': '',
		'position_id': '',
		'card_id': '',
		'image': null,
		'currency': 'BRL',
		'monthly_salary': '',
		'business_unit': '',
		'date_of_birth': '',
	});
	const [profilePhotoObj, setProfilePhotoObject] = useState(null);

	function updateEmployeeFormKey(key, value) {
		// console.log("Updating ", key, value);
		if (key === 'date_of_birth' || key === 'employment_date') {
			let dateValue = new Date(value);
			let yyyy = dateValue.getFullYear();
			let mm = dateValue.getMonth() + 1; // Months start at 0!
			let dd = dateValue.getDate();
			if (dd < 10) dd = '0' + dd;
			if (mm < 10) mm = '0' + mm;
			let formattedDate = yyyy + '-' + mm + '-' + dd;
			value = formattedDate;
			if ( key === 'date_of_birth' ){
				setDateOfBirthInput(dateValue);
			}
			else if ( key === 'employment_date' ){
				setDateOfEmploymentInput(dateValue);
			}
		}
		let employeeFormTemp = JSON.parse(JSON.stringify(employeeFormData));
		employeeFormTemp[key] = value;
		setEmployeeFormData(JSON.parse(JSON.stringify(employeeFormTemp)));
	}

	function updateSalaryInput(input) {
		const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
		let validSalaryInput = input.match(regex)[0];
		updateEmployeeFormKey('monthly_salary', validSalaryInput);
	}

	function formatSalaryInput(input) {
		var parts = input.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}

	function updatePhoneNumberInput(input) {
		if (input.length <= 11) {
			updateEmployeeFormKey('phone_number', input);
		}

	}

	function updateEmergencyPhoneNumberInput(input) {
		if (input.length <= 11) {
			updateEmployeeFormKey('emergency_contact_number', input);
		}
	}

	function formatPhoneNumberInput(input) {
		try {
			input = input.toString();
			if (input.length > 0) {
				input = "(" + input;
				if (input.length > 2) {
					input = input.slice(0, 3) + ") " + input.slice(3);
				}
				if (input.length > 9 && input.length <= 13) {
					input = input.slice(0, 9) + " " + input.slice(9);
				}
				else if (input.length > 13) {
					input = input.slice(0, 10) + " " + input.slice(10);
				}
				return input;
			}
		} catch (error) {
			return "";
		}
		return "";
	}

	function addEmployeeSubmit() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/employee/add";

		setShowLoaderScreenFlag(true);
		let bodyFormData = new FormData();
		bodyFormData.append('login_id', employeeFormData['login_id']);
		bodyFormData.append('password', employeeFormData['password']);
		bodyFormData.append('name', employeeFormData['name']);
		bodyFormData.append('rg_number', employeeFormData['rg_number']);
		bodyFormData.append('cpf_number', employeeFormData['cpf_number']);
		bodyFormData.append('civil_status', employeeFormData['civil_status']);
		bodyFormData.append('address', employeeFormData['address']);
		bodyFormData.append('country_code', employeeFormData['country_code']);
		bodyFormData.append('phone_number', employeeFormData['phone_number']);
		bodyFormData.append('emergency_contact_person', employeeFormData['emergency_contact_person']);
		bodyFormData.append('emergency_contact_number', employeeFormData['emergency_contact_number']);
		bodyFormData.append('number_of_children', employeeFormData['number_of_children']);
		bodyFormData.append('employment_date', employeeFormData['employment_date']);
		bodyFormData.append('ct_number', employeeFormData['ct_number']);
		bodyFormData.append('notes', employeeFormData['notes']);
		bodyFormData.append('remote_employee', employeeFormData['remote_employee']);
		bodyFormData.append('shift_id', employeeFormData['shift_id']);
		bodyFormData.append('contract_id', employeeFormData['contract_id']);
		bodyFormData.append('team_id', employeeFormData['team_id']);
		bodyFormData.append('position_id', employeeFormData['position_id']);
		bodyFormData.append('card_id', employeeFormData['card_id']);
		bodyFormData.append('image', profilePhotoObj);
		bodyFormData.append('currency', employeeFormData['currency']);
		bodyFormData.append('monthly_salary', employeeFormData['monthly_salary']);
		bodyFormData.append('business_unit', employeeFormData['business_unit']);
		bodyFormData.append('date_of_birth', employeeFormData['date_of_birth']);


		let options = { headers: { "Authorization": cookie['token'], "Content-Type": "multipart/form-data" } }

		axios
			.post(api_endpoint, bodyFormData, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				// console.log(response.data);
				// setApiResponseText("Employee registered successfully!");
				setApiResponseText(translateBr("employee_registered_successfully!"));
				props.getAllEmployees();
				setTimeout(() => {
					props.toggleAddEmployeeModal();
				}, 500);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setApiResponseText(translateBr("employee_could_not_be_registered,_please_submit_again!"))
				// setApiResponseText("Employee could not be registered, please submit again!");
			})
	}

	function onProfilePhotoChange(e) {
		// console.log(e.target.files);
		updateEmployeeFormKey('image', e.target.files[0]);
		let profilePhotoFileURL = URL.createObjectURL(e.target.files[0]);
		profilePhotoRef.current.src = profilePhotoFileURL;
		setProfilePhotoObject(e.target.files[0]);
	}

	useEffect(() => {
		// getAllShifts();
		console.log(props.allShifts);
		if (props.allShifts && props.allShifts.length > 0) {
			updateEmployeeFormKey('shift_id', props.allShifts[0]['_id']);
		}

		setAllShiftsArr(props.allShifts);
		return () => {

		}
	}, [])

	useEffect(() => {

		return () => {

		}
	}, [employeeFormData])


	return (
		<>
			<div id="addEmployeeModal" className='modalContainer'>

				<div className='addEmployeeCardContainer'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<div className='cardContainer addEmployeeModalArea'>
									<div className='closeModal' onClick={props.toggleAddEmployeeModal}>
										<img src="/icons/svg/close-1.svg" />
									</div>
									<div className='row employeeIdRow'>
										<div className='col-12'>
											<p>{translateBr("employee_id_-_not_allocated_yet")}</p>
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
											<div className='d-flex justify-content-center'>
												<div className="modalProfilePhotoContainer">
													<div className='editProfilePhotoButton' onClick={() => { inputProfilePhotoFileRef.current.click() }}>
														<img src="/icons/svg/Edit-Button.svg" />
														<input hidden ref={inputProfilePhotoFileRef} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => { onProfilePhotoChange(e) }} />
													</div>
													<div className='photoArea'>
														<img ref={profilePhotoRef} src="/images/Profile-Photo.svg" />
													</div>
												</div>
											</div>
										</div>
										<div className='col-lg-9 col-md-9 col-sm-12 col-xs-12'>
											<div className='inputFormContainer'>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("full_name")}</h1>
													<input type='text' value={employeeFormData['name']} onChange={(e) => updateEmployeeFormKey('name', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("business_unit")}</h1>
													<input type='text' value={employeeFormData['business_unit']} onChange={(e) => updateEmployeeFormKey('business_unit', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("team")}</h1>
													<input type='text' value={employeeFormData['team_id']} onChange={(e) => updateEmployeeFormKey('team_id', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("position")}</h1>
													<input type='text' value={employeeFormData['position_id']} onChange={(e) => updateEmployeeFormKey('position_id', e.target.value)} />
												</div>
												<div className='row'>
													<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
														<div className='inputContainer AddEmployeeFormInputContainer'>
															<h1>{translateBr("date_of_birth")}</h1>
															{/* <input type='date' value={employeeFormData['date_of_birth']} onChange={(e) => updateEmployeeFormKey('date_of_birth', e.target.value)} /> */}
															<DatePicker dateFormat="dd/MM/yyyy" locale="pt"  onChange={(date) => updateEmployeeFormKey('date_of_birth', date)} selected={ dateOfBirthInput } className="dateInput" wrapperClassName="dateInputWrapper" />
														</div>
													</div>
													<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
														<div className='inputContainer AddEmployeeFormInputContainer'>
															<h1>{translateBr("employment_date")}</h1>
															{/* <input type='date' value={employeeFormData['employment_date']} onChange={(e) => updateEmployeeFormKey('employment_date', e.target.value)} /> */}
															<DatePicker dateFormat="dd/MM/yyyy" locale="pt"  onChange={(date) => updateEmployeeFormKey('employment_date', date)} selected={ dateOfEmploymentInput } className="dateInput" wrapperClassName="dateInputWrapper" />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
											<div className='inputFormContainer'>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("rg_number")}</h1>
													<input type='text' value={employeeFormData['rg_number']} onChange={(e) => updateEmployeeFormKey('rg_number', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("cpf_number")}</h1>
													<input type='text' value={employeeFormData['cpf_number']} onChange={(e) => updateEmployeeFormKey('cpf_number', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("civil_status")}</h1>
													<select onChange={(e) => updateEmployeeFormKey('civil_status', e.target.value)} >
														<option value={1}>{translateBr("married")}</option>
														<option value={0}>{translateBr("unmarried")}</option>
													</select>
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("number_of_children")}</h1>
													<input type='text' value={employeeFormData['number_of_children']} onChange={(e) => updateEmployeeFormKey('number_of_children', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("ct_number")}</h1>
													<input type='text' value={employeeFormData['ct_number']} onChange={(e) => updateEmployeeFormKey('ct_number', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("shift")}</h1>
													<select onChange={(e) => updateEmployeeFormKey('shift_id', e.target.value)} >
														{
															allShiftsArr.map((shift, index) => {
																return <option key={index} value={shift['_id']}>{shift['name']}</option>
															})
														}
													</select>
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("login_id")}</h1>
													<input type='text' value={employeeFormData['login_id']} onChange={(e) => updateEmployeeFormKey('login_id', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("remote_employee")}</h1>
													<select onChange={(e) => updateEmployeeFormKey('remote_employee', e.target.value)} >
														<option value={false}>{translateBr("no")}</option>
														<option value={true}>{translateBr("yes")}</option>
													</select>
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("card_id")}</h1>
													<input type='text' value={employeeFormData['card_id']} onChange={(e) => updateEmployeeFormKey('card_id', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("password")}</h1>
													<input type='text' value={employeeFormData['password']} onChange={(e) => updateEmployeeFormKey('password', e.target.value)} />
												</div>
											</div>
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
											<div className='inputFormContainer'>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("address")}</h1>
													<textarea rows="4" value={employeeFormData['address']} onChange={(e) => updateEmployeeFormKey('address', e.target.value)} ></textarea>
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("phone_number")}</h1>

													<div className='salaryInputContainer'>
														<input onClick={() => { phoneNumberInputRef.current.focus(); }} type='text' className={phoneNumberInputActiveFlag ? 'amountTextFocused amountText' : 'amountText'} value={formatPhoneNumberInput(employeeFormData['phone_number'])} onChange={(e) => { }} />
														<input ref={phoneNumberInputRef} type="text" className='amountTextInput' value={employeeFormData['phone_number']} onChange={(e) => updatePhoneNumberInput(e.target.value)} onFocus={() => { setPhoneNumberInputActiveFlag(true) }} onBlur={() => { setPhoneNumberInputActiveFlag(false) }} />
													</div>

												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("emergency_contact_name")}</h1>
													<input type='text' value={employeeFormData['emergency_contact_person']} onChange={(e) => updateEmployeeFormKey('emergency_contact_person', e.target.value)} />
												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("emergency_contact_number")}</h1>

													<div className='salaryInputContainer'>
														<input onClick={() => { emergencyPhoneNumberInputRef.current.focus(); }} type='text' className={emergencyPhoneNumberInputActiveFlag ? 'amountTextFocused amountText' : 'amountText'} value={formatPhoneNumberInput(employeeFormData['emergency_contact_number'])} onChange={(e) => { }} />
														<input ref={emergencyPhoneNumberInputRef} type="text" className='amountTextInput' value={employeeFormData['emergency_contact_number']} onChange={(e) => updateEmergencyPhoneNumberInput(e.target.value)} onFocus={() => { setEmergencyPhoneNumberInputActiveFlag(true) }} onBlur={() => { setEmergencyPhoneNumberInputActiveFlag(false) }} />
													</div>

												</div>
												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("monthly_salary_(_brl_)")}</h1>
													<div className='salaryInputContainer'>
														<input onClick={() => { salaryInputRef.current.focus(); }} type='text' className={salaryInputActiveFlag ? 'amountTextFocused amountText' : 'amountText'} value={formatSalaryInput(employeeFormData['monthly_salary'])} onChange={(e) => { }} />
														<input ref={salaryInputRef} type="text" className='amountTextInput' value={employeeFormData['monthly_salary']} onChange={(e) => updateSalaryInput(e.target.value)} onFocus={() => { setSalaryInputActiveFlag(true) }} onBlur={() => { setSalaryInputActiveFlag(false) }} />
													</div>

												</div>

												<div className='inputContainer AddEmployeeFormInputContainer'>
													<h1>{translateBr("notes")}</h1>
													<textarea rows="8" value={employeeFormData['notes']} onChange={(e) => updateEmployeeFormKey('notes', e.target.value)}></textarea>
												</div>

											</div>
										</div>
										<div className='col-12'>
											<p className='apiResponseText'>{apiResponseText}</p>
											<div className='d-flex justify-content-end AddEmployeeFormActionContainer'>
												<button className='cancelButton' onClick={props.toggleAddEmployeeModal}>{translateBr("cancel")}</button>
												<button className='saveButton' onClick={addEmployeeSubmit}>{translateBr("save")}</button>
											</div>
										</div>
									</div>
									<br />
								</div>
							</div>
						</div>

					</div>
					<br /><br />
				</div>


			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default AddEmployeeModal
