import React, { useState, useEffect } from 'react';
import './AddHolidayModal.css';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function AddHolidayModal(props) {
	const [cookie, setCookie] = useCookies();
	const [eventNameInput, setEventNameInput] = useState("");
	const [eventDescriptionInput, setEventDescriptionInput] = useState("-");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [responseText, setResponseText] = useState("");

	function addEventToDate() {
		if (eventNameInput === "" || eventNameInput === null) {
			setResponseText("Enter Name of the event");
			return;
		}
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/event/add";
		let options = { headers: { "Authorization": cookie['token'] } }
		let body = {
			name: eventNameInput,
			date: props.selectedDateText,
			description: eventDescriptionInput
		}
		setShowLoaderScreenFlag(true);
		axios
			.post(api_endpoint, body, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				setResponseText(responseMsg);
				setTimeout(() => {
					props.toggleAddHolidayModalState();
				}, 200);

			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	useEffect(() => {

		return () => {

		}
	}, [])


	return (
		<>
			{
				props.addHolidayModalState ? <>
					<div id="addHolidayModal" className='modalContainer addHolidayModal'>
						<div className='addHolidayModalArea'>
							<div className='closeModal' onClick={props.toggleAddHolidayModalState}>
								<img src="/icons/svg/close-1.svg" />
							</div>
							<div className='row'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
									<div className='d-flex justify-content-center'>
										<h1>{ translateBr("add_holiday") }</h1>
									</div>

								</div>
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 addHolidayForm'>
								<p>{ translateBr("date") } : {props.selectedDateText}</p>
								<input type='text' placeholder={ translateBr("type_here") } value={eventNameInput} onChange={(e) => setEventNameInput(e.target.value)} />
								<br />
								<p>{responseText}</p>
								<br />
								<div className='d-flex justify-content-end AddHolidayFormActionContainer'>
									<button className='cancelButton' onClick={props.toggleAddHolidayModalState}>{ translateBr("cancel") }</button>
									<button className='saveButton' onClick={addEventToDate}>{ translateBr("save") }</button>
								</div>
							</div>
						</div>
					</div>
					<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
				</> : <>
				</>
			}


		</>
	)
}

export default AddHolidayModal
