import React from 'react';
import './AddDeviceModal.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import LoaderScreen from '../LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function AddDeviceModal(props) {
	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const [deviceNameInput, setDeviceNameInput] = useState("");
	const [deviceSerialNumberInput, setDeviceSerialNumberInput] = useState("");
	const [wifiSSIDInput, setWifiSSIDInput] = useState("ABCDEFGH");
	const [wifiPasswordInput, setWifiPasswordInput] = useState("123456789");

	function AddDeviceSubmit() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/device/register";
		if (deviceNameInput.length <= 0 || deviceSerialNumberInput.length <= 0 || wifiSSIDInput.length <= 0 || wifiPasswordInput.length <= 0) {
			setApiResponseText( translateBr("enter_all_details") );
			return;
		}
		setShowLoaderScreenFlag(true);
		let body = {
			"device_sr": deviceSerialNumberInput,
			"ssid": wifiSSIDInput,
			"password": wifiPasswordInput,
			"device_name": deviceNameInput,
		}

		let options = { headers: { "Authorization": cookie['token'] } }

		// console.log(body);
		axios
			.post(api_endpoint, JSON.parse(JSON.stringify(body)), options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				setApiResponseText(translateBr("device_added_successfully!"));
				// console.log(response.data);
				props.getAllDevices();
				setTimeout(() => {
					props.toggleAddDeviceModalState();
				}, 500)

			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setApiResponseText(translateBr("device_add_failed!"));
			})
	}

	return (
		<>
			<div id="addDeviceModal" className='modalContainer addDeviceModal'>
				<div className='addDeviceModalArea'>
					<div className='closeModal' onClick={props.toggleAddDeviceModalState}>
						<img src="/icons/svg/close-1.svg" />
					</div>
					<div className='row'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
							<div className='d-flex justify-content-center'>
								<h1>{ translateBr("register_device") }</h1>
							</div>

						</div>
					</div>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 AddDeviceForm'>

						<div className='row'>
							<div className='col-12'>
								<div className='addDeviceFormInputContainer'>
									<p>{ translateBr("device_name") }</p>
									<input className='formTextInput' type="text" placeholder={ translateBr("write_here") } value={deviceNameInput} onChange={(e) => setDeviceNameInput(e.target.value)} />
								</div>
							</div>
							<div className='col-12'>
								<div className='addDeviceFormInputContainer'>
									<p>{ translateBr("device_serial_number") }</p>
									<input className='formTextInput' type="text" placeholder={ translateBr("write_here") } value={deviceSerialNumberInput} onChange={(e) => setDeviceSerialNumberInput(e.target.value)} />
								</div>
							</div>
							{/* <div className='col-12'>
								<div className='addDeviceFormInputContainer'>
									<p>{ translateBr("wifi_ssid") }</p>
									<input className='formTextInput' type="text" placeholder={ translateBr("write_here") } value={wifiSSIDInput} onChange={(e) => setWifiSSIDInput(e.target.value)} />
								</div>
							</div>
							<div className='col-12'>
								<div className='addDeviceFormInputContainer'>
									<p>{ translateBr("wifi_password") }</p>
									<input className='formTextInput' type="text" placeholder={ translateBr("minimum_8_character") } value={wifiPasswordInput} onChange={(e) => setWifiPasswordInput(e.target.value)} />
								</div>
							</div> */}
							<div className='col-12'>
								<p>{apiResponseText}</p>
							</div>
						</div>


						<div className='d-flex justify-content-end addDeviceFormActionContainer'>

							<button className='cancelButton' onClick={props.toggleAddDeviceModalState}>{ translateBr("cancel") }</button>
							<button className='saveButton' onClick={AddDeviceSubmit}>{ translateBr("save") }</button>
						</div>
					</div>
				</div>
			</div>

			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default AddDeviceModal
