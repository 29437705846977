import React from 'react';
import './Requests.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import TimesheetEmployeesListContainer from '../../components/TimesheetEmployeesListContainer/TimesheetEmployeesListContainer';
import EmployeeTimestampTableContainer from '../../components/EmployeeTimestampTableContainer/EmployeeTimestampTableContainer';
import NewRequestCard from '../../components/NewRequestCard/NewRequestCard';
import PastRequestCard from '../../components/PastRequestCard/PastRequestCard';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function Requests() {
	const [cookie, setCookie] = useCookies();
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [tabsData, setTabsdata] = useState([{ id: "new_requests", title: "New Requests", active: true }, { id: "past_requests", title: "Past Requests", active: false }]);
	const [activeTabId, setActiveTabId] = useState("clocked_in");
	const [leaveRequestsArr, setLeaveRequestsArr] = useState([]);

	function changeTab(tab_id) {
		let tempTabsData = JSON.parse(JSON.stringify(tabsData));
		for (let i = 0; i < tempTabsData.length; i++) {
			if (tempTabsData[i]['id'] === tab_id) {
				tempTabsData[i]['active'] = true;
			}
			else {
				tempTabsData[i]['active'] = false;
			}
		}
		setTabsdata(JSON.parse(JSON.stringify(tempTabsData)));
		setActiveTabId(tab_id);
		if ( tab_id === "past_requests" ){
			getLeaveRequests(1);
		}
		else if ( tab_id === "new_requests" ){
			getLeaveRequests(0);
		}
	}

	function formatDate(date) {
		let value = new Date(date);
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		return formattedDate;
	}

	function getLeaveRequests( type ) {
		setShowLoaderScreenFlag(true);

		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/leave/getall";
		let options = { headers: { "Authorization": cookie['token'] }, params: { 'type': type } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				let leaveRequestsArrTemp = response.data.data;
				if ( response.data.data.length === 0 ){
					leaveRequestsArrTemp = []
				}
				setLeaveRequestsArr( JSON.parse( JSON.stringify(leaveRequestsArrTemp) ) );
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	useEffect(() => {
		getLeaveRequests(0);
	
		return () => {
			
		}
	}, [])
	

	return (
		<>
			<div id="requests-page">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{ translateBr("leave_requests") }</h1>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<div className='row'>
										<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
											<div className='tabsContainer'>
												{
													tabsData.map((tabData, index) => {
														return <>
															<div key={index} className={tabData.active === true ? "tab activeTab" : "tab"} onClick={() => { changeTab(tabData.id) }}>
																<p>{tabData.title}</p>
															</div>
														</>
													})
												}
											</div>
										</div>
										<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
											{
												leaveRequestsArr.map( ( request, index ) => {
													if ( request['status'] === 0 ){
														return <NewRequestCard key={index} requestDetails={request} setShowLoaderScreenFlag={setShowLoaderScreenFlag} getLeaveRequests={getLeaveRequests} />
													}
													else {
														return <PastRequestCard key={index} requestDetails={request} setShowLoaderScreenFlag={setShowLoaderScreenFlag} />
													}
												} )
											}
											
											{/* <NewRequestCard />
											<NewRequestCard />
											<NewRequestCard />
											<PastRequestCard /> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						<br /><br />
					</div>
				</div>
			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default Requests
