import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import React, { useEffect, useState } from 'react';

import translation from "./json/translate.json";
import './App.css';
import Login from './pages/Login/Login';
import CompanyRegister from "./pages/CompanyRegister/CompanyRegister";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/navbar";
import Employees from "./pages/Employees/Employees";
import Timesheet from "./pages/Timesheet/Timesheet";
import Requests from "./pages/Requests/Requests";
import HolidayCalendar from "./pages/HolidayCalendar/HolidayCalendar";
import Shifts from "./pages/Shifts/Shifts";
import Account from "./pages/Account/Account";
import Devices from "./pages/Devices/Devices";
import Reports from "./pages/Reports/Reports";

import { useCookies } from 'react-cookie';
import RedirectComp from './pages/RedirectComp/index';

window.$translate = JSON.parse(JSON.stringify(translation));

function App() {
	const [cookie, setCookie] = useCookies();
	const [mobileNavbarState, setMobileNavbarState] = useState(false);
	const [desktopNavbarState, setDesktopNavbarState] = useState(true);

	return (
		<>
			<Router>
				<Routes>

					<Route exact path="/login" element={<Login />} />
					<Route exact path="/company-register" element={<CompanyRegister />} />
					<Route exact path="/*" element={
						<>
							<div className="container-fluid p-0">
								<div className="">
									<Navbar setMobileNavbarState={setMobileNavbarState} mobileNavbarState={mobileNavbarState} desktopNavbarState={desktopNavbarState} setDesktopNavbarState={setDesktopNavbarState} />
									<div className={desktopNavbarState ? 'mainContainer' : 'collapsedMenuMainContainer'}>
										<Routes>
											<Route exact path="/" element={<RedirectComp />} />
											<Route exact path="/home" element={<><Home /></>}></Route>
											<Route exact path="/employees" element={<><Employees /></>}></Route>
											<Route exact path="/timesheet" element={<><Timesheet /></>}></Route>
											<Route exact path="/requests" element={<><Requests /></>}></Route>
											<Route exact path="/calendar" element={<><HolidayCalendar /></>}></Route>
											<Route exact path="/shifts" element={<><Shifts /></>}></Route>
											<Route exact path="/account" element={<><Account /></>}></Route>
											<Route exact path="/devices" element={<><Devices /></>}></Route>
											<Route exact path="/reports" element={<><Reports /></>}></Route>
										</Routes>
									</div>

								</div>
							</div>

						</>
					} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
