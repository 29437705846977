import React from "react";
import "./WarningPopup.css";

function WarningPopup(props) {
	let showFlag = props.showFlag;
	return (
		<>
			<div id="warningModal">
				<div className="warningModalBackground d-flex justify-content-center">
					<div className="warningModal">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<div className="modalHeadingArea">
										<h1 className="modalHeading">Warning!!</h1>
									</div>
									<div className="modalBodyArea">
										<p className="modalMessage">{ props.message }</p>
									</div>
									<div className="modalActionArea">
										<div className="row">
											<div className="col-6">
												<button className="cancelButton" onClick={props.onCancel}>Cancel</button>
											</div>
											<div className="col-6">
												<button className="saveButton" onClick={props.onConfirm}>Confirm</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	);
}

export default WarningPopup;
