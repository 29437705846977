import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

function RedirectComp() {
	let navigate = useNavigate();
	const [cookie, setCookie] = useCookies();

	useEffect(() => {
		if ( cookie['token'] && cookie['token'] != null && cookie['token'] != "" ){
			console.log(cookie['token']);
			navigate("/home");
		}
	
		return () => {
			
		}
	}, [])
	

	return (
		<>
		</>
	)
}

export default RedirectComp
