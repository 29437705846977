import React from 'react';
import './Employees.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';
import { translateBr } from '../../functions/genericFunctions';

import EmployeeTableContainer from '../../components/EmployeeTableContainer/EmployeeTableContainer';
import EmployeeDetailsContainer from '../../components/EmployeeDetailsContainer/EmployeeDetailsContainer';
import AddEmployeeModal from '../../components/AddEmployeeModal/AddEmployeeModal';
import EditEmployeeModal from '../../components/EditEmployeeModal/EditEmployeeModal';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import EmployeeDetailsMobileModal from '../../components/EmployeeDetailsMobileModal/EmployeeDetailsMobileModal';
import WarningPopup from '../../components/WarningModal/WarningPopup';

function Employees() {
	const [cookie, setCookie] = useCookies();
	const [warningModalState, setWarningModalState] = useState(false);
	const [ deleteEmployeeID, setDeleteEmployeeID ] = useState(null);
	const [ warningModalMessage, setWarningModalMessage ] = useState("");
	const [addEmployeeModalState, setAddEmployeeModalState] = useState(false);
	const [editEmployeeModalState, setEditEmployeeModalState] = useState(false);
	const [allShifts, setAllShifts] = useState([]);
	const [allEmployees, setAllEmployees] = useState([]);
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState(null);
	const [mobileEmployeeDetailsModalState, setMobileEmployeeDetailsModalState] = useState(false);

	const [webSocketClient, setWebSocketClient] = useState(null);
	const [webSocketConnFlag, setWebSocketConnFlag] = useState(false);
	const [employeeInDeviceState, setEmployeeInDeviceState] = useState(false);
	let ws = null;

	function checkUserInDevice(employeeCardId) {
		let companyId = cookie['userData']['company_id'];
		// console.log(companyId);
		let messageObj = { "webclientcmd": "checkuserindevice", "school_id": companyId, "enroll_id": employeeCardId };
		webSocketClient.send(JSON.stringify(messageObj));
	}

	function saveUserToDevice(ideiacard_id, name) {
		let companyId = cookie['userData']['company_id'];
		let messageObj = { "webclientcmd": "setuserinfo", "school_id": companyId, "data": { "enrollid": ideiacard_id, "name": name } };
		webSocketClient.send(JSON.stringify(messageObj));
	}

	function deleteUserFromDevice( ideiacard_id ){
		let companyId = cookie['userData']['company_id'];
		let messageObj = { "webclientcmd": "deleteuser", "school_id": companyId, "data": { "enrollid": ideiacard_id } };
		webSocketClient.send(JSON.stringify(messageObj));
	}

	useEffect(() => {
		if (webSocketConnFlag === false) {
			// console.log("Web Socket Connecting .... ");
			ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_SERVER_ENDPOINT);

			ws.onopen = function () {
				console.log("Client Connected");
				setWebSocketConnFlag(true);
				let companyId = cookie['userData']['company_id'];
				let messageObj = { "webclientcmd": "webclientstatus", "school_id": companyId };
				ws.send(JSON.stringify(messageObj));
			};

			ws.onmessage = function (evt) {
				let received_msg = evt.data;
				// console.log("Message is received...", received_msg);
				let jsonMsg = JSON.parse(received_msg);
				console.log(jsonMsg);
				if (jsonMsg['data']['result'] === false) {
					setEmployeeInDeviceState(false);
				}
				else {
					setEmployeeInDeviceState(true);
				}
			};

			ws.onclose = function () {
				console.log("Connection is closed...");
				setTimeout(() => {
					setWebSocketConnFlag(false);
				}, 10000);
				

			};
			setWebSocketClient(ws);
		}
		else {
			console.log("WebSocket Client Already Connected");
		}

		return () => {

		}
	}, [])



	function toggleAddEmployeeModal() {
		if (addEmployeeModalState) {
			setAddEmployeeModalState(false);
		}
		else {
			setAddEmployeeModalState(true);
		}
	}

	function toggleEditEmployeeModal() {
		if (editEmployeeModalState) {
			setEditEmployeeModalState(false);
		}
		else {
			setEditEmployeeModalState(true);
		}
	}

	function closeMobileModal() {
		setMobileEmployeeDetailsModalState(false);
	}

	function openMobileModal() {
		setMobileEmployeeDetailsModalState(true);
	}

	function formatDate(date) {
		let value = new Date(date);
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		return formattedDate;
	}


	function getAllShifts() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/shift/getall";
		let options = { headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				setAllShifts(response.data.data);
			})
			.catch(error => {
				console.log(error.response.data);
			})
	}

	function getAllEmployees() {
		let todayDateTemp = formatDate((new Date()).toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));

		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/attendance/employstatus/" + todayDateTemp;
		let options = { headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				setAllEmployees(response.data.data);
				if (response.data.data.length > 0) {
					// console.log(response.data.data[0]);
					selectEmployee(response.data.data[0]);
				}
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	function selectEmployee(employee) {
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/employee/" + employee['id'];
		let options = { headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				// console.log(response.data.data);
				setSelectedEmployeeDetails(JSON.parse(JSON.stringify(response.data.data)));
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	function deleteEmployee() {
		// console.log(deleteEmployeeID);
		let employeeCardID = deleteEmployeeID['card']['card_id'];
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/employee/delete/" + deleteEmployeeID['_id'];
		let options = { headers: { "Authorization": cookie['token'] } }
		setShowLoaderScreenFlag(true);
		axios
			.delete(api_endpoint, options)
			.then((response) => {
				console.log(response);
				setShowLoaderScreenFlag(false);
				getAllEmployees();
				setWarningModalState(false);
				deleteUserFromDevice(employeeCardID);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setWarningModalState(false);
			})
	}

	function openDeleteEmployeeWarningModal(employeeObj){
		setDeleteEmployeeID(employeeObj);
		setWarningModalState(true);
		let warningMessage = "Are you sure? User "+employeeObj['name']+" will be removed from the system.";
		setWarningModalMessage( warningMessage );
	}


	useEffect(() => {
		setShowLoaderScreenFlag(true);
		getAllShifts();
		getAllEmployees();
		return () => {

		}
	}, [])

	return (
		<>
			<div id="home-page">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{translateBr("employees")}</h1>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-12'>
								<div className='cardContainer'>
									<div className='row'>
										<div className='col-12'>
											<div className='d-flex flex-row-reverse addEmployeeRow'>
												<button onClick={toggleAddEmployeeModal}>{translateBr("add_employee")}</button>
											</div>
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
											<EmployeeTableContainer allEmployees={allEmployees} setSelectedEmployeeDetails={selectEmployee} openMobileModal={openMobileModal} />
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
											<EmployeeDetailsContainer webSocketConnFlag={webSocketConnFlag} employeeInDeviceState={employeeInDeviceState} checkUserInDevice={checkUserInDevice} saveUserToDevice={saveUserToDevice} toggleEditEmployeeModal={toggleEditEmployeeModal} selectedEmployeeDetails={selectedEmployeeDetails} deleteEmployee={openDeleteEmployeeWarningModal} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<br /><br />
					</div>


				</div>
			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
			{
				mobileEmployeeDetailsModalState ? <>
					<EmployeeDetailsMobileModal webSocketConnFlag={webSocketConnFlag} employeeInDeviceState={employeeInDeviceState} checkUserInDevice={checkUserInDevice} saveUserToDevice={saveUserToDevice} toggleEditEmployeeModal={toggleEditEmployeeModal} selectedEmployeeDetails={selectedEmployeeDetails} closeMobileModal={closeMobileModal} deleteEmployee={openDeleteEmployeeWarningModal} />
				</> : <></>
			}

			{
				addEmployeeModalState ? <>
					<AddEmployeeModal getAllEmployees={getAllEmployees} toggleAddEmployeeModal={toggleAddEmployeeModal} allShifts={allShifts} />
				</> : <></>
			}
			{
				editEmployeeModalState ? <>
					<EditEmployeeModal getAllEmployees={getAllEmployees} selectedEmployeeDetails={selectedEmployeeDetails} toggleEditEmployeeModal={toggleEditEmployeeModal} allShifts={allShifts} />
				</> : <></>
			}
			{
				warningModalState ? <>
					<WarningPopup onCancel={ ()=> { setWarningModalState(false) } } onConfirm={ deleteEmployee } message={ warningModalMessage } />
				</>:<></>
			}
		</>
	)
}

export default Employees
