import React from 'react';
import './Account.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';


function Account() {
	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const [passwordChangeFormState, setPasswordChangeFormState] = useState(false);
	const [oldPasswordInput, setOldPasswordInput] = useState("");
	const [newPasswordInput, setNewPasswordInput] = useState("");
	const [companyDetails, setCompanyDetails] = useState({});

	function formatPhoneNumberInput(input) {
		input = input.toString();
		if (input.length > 0) {
			input = "(" + input;
			if (input.length > 2) {
				input = input.slice(0, 3) + ") " + input.slice(3);
			}
			if (input.length > 9 && input.length <= 13) {
				input = input.slice(0, 9) + " " + input.slice(9);
			}
			else if (input.length > 13) {
				input = input.slice(0, 10) + " " + input.slice(10);
			}
		}
		return input;
	}

	function togglePasswordChangeFormState() {
		setPasswordChangeFormState(!passwordChangeFormState);
	}

	function convertPhoneObjectToString(phoneNumberObj) {
		console.log("Phone Number Object", phoneNumberObj);
		if (phoneNumberObj === undefined || phoneNumberObj === null || phoneNumberObj === {}) {
			return "";
		}
		try {
			let countryCode = phoneNumberObj['country_code'];
			let phoneNumber = phoneNumberObj['number'].toString();
			phoneNumber = formatPhoneNumberInput(phoneNumber);
			return countryCode + " " + phoneNumber;
		} catch (error) {
			return "";
		}

	}

	function getAccountDetails() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/company/get";
		setShowLoaderScreenFlag(true);

		let options = { headers: { "Authorization": cookie['token'] } }

		axios
			.get(api_endpoint, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				setCompanyDetails(JSON.parse(JSON.stringify(response.data.data)));
			})
			.catch(error => {
				setShowLoaderScreenFlag(false);
			})
	}

	function updatePassword() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/admin/update/password";

		setShowLoaderScreenFlag(true);
		let body = {
			old_password: oldPasswordInput,
			new_password: newPasswordInput
		}

		let options = { headers: { "Authorization": cookie['token'] } }

		console.log({ options, body });
		axios
			.post(api_endpoint, JSON.parse(JSON.stringify(body)), options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				setApiResponseText("Password reset successfully");

				console.log(response.data);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setApiResponseText("Password reset failed");
			})
	}

	useEffect(() => {
		getAccountDetails();

		return () => {

		}
	}, [])


	return (
		<>
			{
				companyDetails['_id'] ? <>
					<div id="requests-page">
						<div className="mainContentContainer">
							<div className='container-fluid'>
								<div className='row'>
									<div className='col-12'>
										<div className='pageTitleContainer'>
											<h1>{ translateBr("account") }</h1>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
										<div className='cardContainer'>
											<div className='row'>
												<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
													<div className='d-flex justify-content-center'>
														<div className='accountHolderDetailsContainer'>
															<div className='profileHeaderContainer'>
																<h1>{companyDetails['name'] ? companyDetails['name'] : ""}</h1>
																<p>{ translateBr("company_id") } - {companyDetails['company_id'] ? companyDetails['company_id'] : ""}</p>
																<p>{companyDetails['address']}</p>
															</div>
															<div className='profileDetailsContainer'>
																<div className='profileDetailsCard'>
																	<p>{ translateBr("company_registration_number") } - {companyDetails['company_registration_number'] ? companyDetails['company_registration_number'] : ""}</p>
																	<p>{ translateBr("contact_number") } - {convertPhoneObjectToString(companyDetails['phone_number'] ? companyDetails['phone_number'] : null)}</p>
																	<p>{ translateBr("admin_email") } - {companyDetails['email'] ? companyDetails['email'] : ""}</p>
																	<p>{ translateBr("website") } - {companyDetails['website'] ? companyDetails['website'] : ""}</p>
																	<p>{ translateBr("password") } - ****** ( <a onClick={togglePasswordChangeFormState} className='passwordChangeButton'>{ translateBr("update") }</a> ) </p>
																</div>
																<br />
																{
																	passwordChangeFormState ? <>
																		<div className='passwordFormCard'>
																			<p className='apiResponseText'>{apiResponseText}</p>
																			<input type='password' placeholder={ translateBr("old_password") } value={oldPasswordInput} onChange={(e) => setOldPasswordInput(e.target.value)} />
																			<input type='password' placeholder={ translateBr("new_password") } value={newPasswordInput} onChange={(e) => setNewPasswordInput(e.target.value)} />
																			<div className='d-flex justify-content-end'>
																				<button className='cancelButton' onClick={togglePasswordChangeFormState}>{ translateBr("cancel") }</button>
																				<button className='updateButton' onClick={updatePassword}>{ translateBr("update") }</button>
																			</div>
																		</div>
																	</> : <>
																	</>
																}
															</div>

														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
								<br /><br />
							</div>
						</div>
					</div>
				</> : <></>
			}
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default Account
