import React, { useEffect } from 'react';
import './MapLocationModal.css';
import { useState } from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

import { translateBr } from '../../functions/genericFunctions';

function MapLocationModal(props) {
	const [locationObj, setLocationObj] = useState({ lat: 10.99835602, lng: 77.01502627 });
	// console.log("location data", props.locationData);

	useEffect(() => {
		console.log(props.locationData);
		try {
			let lat = props.locationData['location']['lat'];
			let lng = props.locationData['location']['long'];
			let locationObjTemp = { lat: lat, lng: lng };
			setLocationObj(JSON.parse(JSON.stringify(locationObjTemp)));
		} catch (error) {

		}


		return () => {

		}
	}, [props.locationData])

	return (
		<>
			{
				props.modalState ? <>
					<div id="mapLocationModal" className='modalContainer mapLocationModal'>
						<div className='mapLocationModalArea'>
							<div className='closeModal' onClick={props.toggleModal}>
								<img src="/icons/svg/close-1.svg" />
							</div>
							<div className='row'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
									<div className='d-flex justify-content-center'>
										<h1>{translateBr("register_device")}</h1>
									</div>

								</div>
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mapLocationForm'>
								<div className='mapContainer'>
									<APIProvider apiKey={"AIzaSyCAwHD6jCZNwvrkpzi17gzrbXQpf1KQRYA"}>
										<Map
											zoom={15}
											center={locationObj}
											gestureHandling={'greedy'}
											disableDefaultUI={true}
										>
											<Marker position={locationObj} />
										</Map>
									</APIProvider>
								</div>
								<br />
								<div className='d-flex justify-content-center mapLocationFormActionContainer'>
									<button className='cancelButton' onClick={props.toggleModal}>{translateBr("cancel")}</button>
								</div>
							</div>
						</div>
					</div>
				</> : <></>
			}

		</>
	)
}

export default MapLocationModal
