import React from 'react';
import './TimesheetEmployeesListContainer.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { translateBr } from '../../functions/genericFunctions';



function TimesheetEmployeesListContainer(props) {
	return (
		<>
			<div className='TimesheetEmployeesTableContainer'>
				<table>
					<colgroup>
						<col span="1" style={{ width: "40%" }} />
						<col span="1" style={{ width: "30%" }} />
						<col span="1" style={{ width: "30%" }} />
					</colgroup>
					<thead>
						<tr>
							<th>{ translateBr("name") }</th>
							<th>{ translateBr("employee_id") }</th>
							<th>{ translateBr("status") }</th>
						</tr>
					</thead>
					<tbody>
						{
							props.allEmployees.map((employee, index) => {
								return (
									<tr key={index} className={ props.selectedEmployeeIndex === index ? 'activeRow' : '' } onClick={ () => { props.setSelectedEmployeeIndex(index) } }>
										<td>{employee['name']}</td>
										<td>{employee['employee_id']}</td>
										<td>
											{
												employee['status'] === 0 ? <>
													<button className='employeeStatusButton clockedOutStatus'>
														{ translateBr("clocked_out") }
													</button>
												</> : employee['status'] === 1 ? <>
													<button className='employeeStatusButton clockedInStatus'>
														{ translateBr("clocked_in") }
													</button>
												</> : employee['status'] === 2 ? <>
													<button className='employeeStatusButton onLeaveStatus'>
														{ translateBr("on_leave") }
													</button>
												</> : employee['status'] === 3 ? <>
													<button className='employeeStatusButton unknownStatus'>
														{ translateBr("unknown") }
													</button>
												</> : <>
												</>
											}
										</td>

									</tr>
								)
							})
						}
					</tbody>
				</table>
				{/* <div className='d-flex flex-row-reverse listActionContainer'>
					<button className='listActionButton nextButton'>Next</button>
					<button className='listActionButton prevButton'>Previous</button>
				</div> */}
			</div>

		</>
	)
}

export default TimesheetEmployeesListContainer
