import React from 'react';
import './AddShiftModal.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import LoaderScreen from '../LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function AddShiftModal(props) {
	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const [shiftName, setShiftName] = useState("");
	const [shiftStartTime, setShiftStartTime] = useState("00:00");
	const [shiftEndTime, setShiftEndTime] = useState("00:00");
	const [shiftIntervalStartTime, setShiftIntervalStartTime] = useState("00:00");
	const [shiftIntervalEndTime, setShiftIntervalEndTime] = useState("00:00");
	const [shiftActiveDaysArr, setShiftActiveDaysArr] = useState([0, 0, 0, 0, 0, 0, 0]);
	const [shiftActiveDays, setShiftActiveDays] = useState([]);

	function updateShiftActiveDaysArr(index, value) {
		let shiftActiveDaysArrTemp = JSON.parse(JSON.stringify(shiftActiveDaysArr));
		shiftActiveDaysArrTemp[index] = Number(value);
		setShiftActiveDaysArr(JSON.parse(JSON.stringify(shiftActiveDaysArrTemp)));
		let activeDaysArr = [];
		for (let i = 0; i < shiftActiveDaysArrTemp.length; i++) {
			if (shiftActiveDaysArrTemp[i] === 1) {
				activeDaysArr.push(i);
			}
		}
		setShiftActiveDays(activeDaysArr);
	}

	function addShiftSubmit() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/shift/add";

		setShowLoaderScreenFlag(true);
		let body = {
			name: shiftName,
			start_time: shiftStartTime,
			end_time: shiftEndTime,
			days_of_week: shiftActiveDays,
			interval_start: shiftIntervalStartTime,
			interval_end: shiftIntervalEndTime,
		}

		let options = { headers: { "Authorization": cookie['token'] } }

		console.log(body);
		axios
			.post(api_endpoint, JSON.parse(JSON.stringify(body)), options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				console.log(response.data);
				props.toggleAddShiftModalState();
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	return (
		<>
			{
				props.AddShiftModalState ? <>
					<div id="AddShiftModal" className='modalContainer AddShiftModal'>
						<div className='AddShiftModalArea'>
							<div className='closeModal' onClick={props.toggleAddShiftModalState}>
								<img src="/icons/svg/close-1.svg" />
							</div>
							<div className='row'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
									<div className='d-flex justify-content-center'>
										<h1>{ translateBr("add_shift") }</h1>
									</div>

								</div>
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 addShiftForm'>

								<div className='row'>
									<div className='col-12'>
										<div className='addShiftFormInputContainer'>
											<p>{ translateBr("shift_name") }</p>
											<input className='formTextInput' type="text" placeholder='Vespertino' value={shiftName} onChange={(e) => setShiftName(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{ translateBr("start-time") }</p>
											<input className='formTextInput' type="time" value={shiftStartTime} onChange={(e) => setShiftStartTime(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{ translateBr("end-time") }</p>
											<input className='formTextInput' type="time" value={shiftEndTime} onChange={(e) => setShiftEndTime(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{ translateBr("shift_interval_start_time") }</p>
											<input className='formTextInput' type="time" value={shiftIntervalStartTime} onChange={(e) => setShiftIntervalStartTime(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{ translateBr("shift_interval_end_time") }</p>
											<input className='formTextInput' type="time" value={shiftIntervalEndTime} onChange={(e) => setShiftIntervalEndTime(e.target.value)} />
										</div>
									</div>
									<div className='col-12'>
										<div className='addShiftFormInputContainer'>
											<p>{ translateBr("days") }</p>
											<div className='d-flex addShiftFormActiveDaysCheckboxesContainer'>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="mon" name="mon" value="mon" onChange={(e) => { updateShiftActiveDaysArr(0, e.target.checked) }} />
													<label className='checkboxLabel' htmlFor="mon">{ translateBr("mon") }</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="tue" name="tue" value="tue" onChange={(e) => { updateShiftActiveDaysArr(1, e.target.checked) }} />
													<label className='checkboxLabel' htmlFor="tue">{ translateBr("tue") }</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="wed" name="wed" value="wed" onChange={(e) => { updateShiftActiveDaysArr(2, e.target.checked) }} />
													<label className='checkboxLabel' htmlFor="wed">{ translateBr("wed") }</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="thu" name="thu" value="thu" onChange={(e) => { updateShiftActiveDaysArr(3, e.target.checked) }} />
													<label className='checkboxLabel' htmlFor="thu">{ translateBr("thu") }</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="fri" name="fri" value="fri" onChange={(e) => { updateShiftActiveDaysArr(4, e.target.checked) }} />
													<label className='checkboxLabel' htmlFor="fri">{ translateBr("fri") }</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="sat" name="sat" value="sat" onChange={(e) => { updateShiftActiveDaysArr(5, e.target.checked) }} />
													<label className='checkboxLabel' htmlFor="sat">{ translateBr("sat") }</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="sun" name="sun" value="sun" onChange={(e) => { updateShiftActiveDaysArr(6, e.target.checked) }} />
													<label className='checkboxLabel' htmlFor="sun">{ translateBr("sun") }</label><br />
												</div>
											</div>
										</div>
									</div>
								</div>


								<div className='d-flex justify-content-end addShiftFormActionContainer'>
									<button className='cancelButton' onClick={props.toggleAddShiftModalState}>{ translateBr("cancel") }</button>
									<button className='saveButton' onClick={addShiftSubmit}>{ translateBr("save") }</button>
								</div>
							</div>
						</div>
					</div>
				</> : <>
				</>
			}

			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default AddShiftModal
