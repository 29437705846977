import React from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/navbar';
import axios from 'axios';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { useCookies } from 'react-cookie';

function Login() {
	let navigate = useNavigate();
	const [loginIdInput, setLoginIdInput] = useState("");
	const [passwordInput, setPasswordInput] = useState("");
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const [cookie, setCookie] = useCookies();

	function submitForm() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/user/login";

		setShowLoaderScreenFlag(true);
		let body = {
			email: loginIdInput,
			password: passwordInput
		}
		axios
			.post(api_endpoint, JSON.parse(JSON.stringify(body)))
			.then((response) => {
				setShowLoaderScreenFlag(false);
				// console.log(response.data);
				let responseMsg = response.data.message;
				setApiResponseText(window.$translate["login_success"]["br"]);

				setCookie("token", response.data.data.accessToken, { path: "/" });
				setCookie("userData", response.data.data.userData, { path: "/" });
				setTimeout(() => {
					navigate("/home");
				}, 500);
				console.log(response.data);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setApiResponseText(window.$translate["login_failed"]["br"]);
			})
	}

	useEffect(() => {
		if ( cookie['token'] && cookie['token'] != null && cookie['token'] != "" ){
			console.log(cookie['token']);
			navigate("/home");
		}
	
		return () => {
			
		}
	}, [])
	

	return (
		<>
			<div id="login-page">

				<div className="container-fluid">
					<div className='row'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
							<div className='loginFormContainer'>
								<div className='loginLogoContainer'>
									<img src="/icons/svg/ideiacard-logo.svg" />
								</div>
								<br /><br /><br />
								<div className='cardContainer loginFormInputContainer'>
									<h1>{window.$translate["employee_management"]["br"]}</h1>
									<div className='loginFormInput'>
										<input type='text' placeholder={window.$translate["login_id"]["br"]} value={loginIdInput} onChange={(e) => setLoginIdInput(e.target.value)} />
									</div>
									<div className='loginFormInput'>
										<input type='password' placeholder={window.$translate["password"]["br"]} value={passwordInput} onChange={(e) => setPasswordInput(e.target.value)} />
									</div>
									<div className='row'>
										<div className='col-6'>
											<div className='keepSignedInInput'>
												<input type="checkbox" id="keepSignedInCheckbox" checked disabled={true} />
												<label htmlFor="keepSignedInCheckbox">{window.$translate["keep_me_signed_in"]["br"]}</label>
											</div>
										</div>
										<div className='col-6'>
											<div className='forgotPasswordContainer'>
												<a>{window.$translate["forgot_password"]["br"]}</a>
											</div>
										</div>
									</div>
									<br /><br />
									<p className='apiResponseText'>{apiResponseText}</p>
									<div className='row'>
										<div className='col-12'>
											<button className='loginButton' onClick={submitForm}>{window.$translate["sign_in"]["br"]}</button>
										</div>
									</div>
									<br /><br />

								</div>
							</div>


						</div>
					</div>
					<br /><br />
					<br /><br />

				</div>
			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default Login
