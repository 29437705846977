import React, { useEffect } from 'react';
import './EmployeeDetailsMobileModal.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { translateBr } from '../../functions/genericFunctions';

function EmployeeDetailsMobileModal(props) {
	const profilePhotoRef = React.useRef();
	let details = props.selectedEmployeeDetails;
	function formatDate(date) {
		let value = new Date(date);
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		return formattedDate;
	}

	function formatPhoneNumberInput(input) {
		try {
			input = input.toString();
			if (input.length > 0) {
				input = "(" + input;
				if (input.length > 2) {
					input = input.slice(0, 3) + ") " + input.slice(3);
				}
				if (input.length > 9 && input.length <= 13) {
					input = input.slice(0, 9) + " " + input.slice(9);
				}
				else if (input.length > 13) {
					input = input.slice(0, 10) + " " + input.slice(10);
				}
				return input;
			}
		} catch (error) {
			return "";
		}
	}

	function setDefaultProfilePicture() {
		// console.log("Setting Default Photo");
		profilePhotoRef.current.src = "/images/Profile-Photo.png";
	}

	function setProfilePicture(imageUrl) {
		profilePhotoRef.current.src = imageUrl;
	}

	function cancelModal() {
		props.closeMobileModal();
	}

	function editEmployeeProfile() {
		props.toggleEditEmployeeModal();
		cancelModal();
	}

	function saveUserInDevice() {
		// console.log(details);
		try {
			props.saveUserToDevice(details['card']['card_id'], details['name']);
		} catch (error) {
			console.log(error);
		}
	}

	function deleteEmployee(){
		props.deleteEmployee(details['_id']);
		console.log(details);
	}

	useEffect(() => {
		if (details != null) {
			setProfilePicture(details['picture']);
			if (details['picture'] && details['picture'] != "") {
				setProfilePicture(details['picture']);
			}
		}

		try {
			props.checkUserInDevice(details['card']['card_id']);
		} catch (error) {
			console.log(error);
		}

		return () => {

		}
	}, [props.selectedEmployeeDetails])


	return (
		<>
			{
				details != null && details != undefined ? <>
					<div id="employeeDetailsModalContainer" className='employeeDetailsModalContainer'>
						<div className='employeeDetailsContainer'>
							<div className='cancelButtonContainer'>
								<button onClick={cancelModal}>Cancel</button>
							</div>
							<div className='row'>
								<div className='col-12'>
									<div className='d-flex flex-row-reverse editEmployeeRow'>
										<img src="/icons/svg/Edit-Button.svg" onClick={editEmployeeProfile} />
									</div>
								</div>
								<div className='col-12'>
									<div className='d-flex justify-content-center profilePhotoContainer'>
										<img ref={profilePhotoRef} src={"/images/Profile-Photo.png"} loading='lazy' onError={setDefaultProfilePicture} />
									</div>
								</div>
								<div className='col-12'>
									<div className='employeeDetailsTextArea'>
										<p>{translateBr("employee_id")} - {details['employee_id']}</p>
										<p>{translateBr("name")} - {details['name']}</p>
										<p>{translateBr("rg_number")} - {details['rg_number']}</p>
										<p>{translateBr("cpf_number")} - {details['cpf_number']}</p>
										<p>{translateBr("civil_status")} - {details['civil_status'] ? translateBr("married") : translateBr("unmarried")}</p>
										<p>{translateBr("address")} - {details['address']}</p>
										<p>{translateBr("phone_number")} - {details['phone_number']['country_code'] + " " + formatPhoneNumberInput(details['phone_number']['number'])}</p>
										<p>{translateBr("emergency_contact_number")} - {details['emergency_contact_number']['country_code'] + " " + formatPhoneNumberInput(details['emergency_contact_number']['number'])}</p>
										<p>{translateBr("emergency_contact_name")} - {details['emergency_contact_person']}</p>
										<p>{translateBr("number_of_children")} - {details['number_of_children']}</p>
										<p>{translateBr("employment_date")} - {formatDate(details['employment_date'])}</p>
										<p>{translateBr("ct_number")} - {details['ct_number']}</p>
										<p>{translateBr("notes")} - {details['notes']}</p>
										<p>{translateBr("remote_employee")} - {details['remote_employee'] ? translateBr("yes") : translateBr("no")}</p>
										<br />
									</div>
									<div className='row employeeDeviceActionArea'>
										<div className='col-12'>
											<div className='d-flex justify-content-end'>
												{
													props.employeeInDeviceState ? <>
														<p className='userSavedInDevice'>User Saved in Device</p>
													</> : <>
														<button onClick={saveUserInDevice} className='saveUserInDeviceButton' >Save User in Device</button>
													</>
												}
												<button className='deleteEmployeeButton' onClick={deleteEmployee}>Delete Employee</button>
											</div>
										</div>
									</div>
									<div className='bottomCancelButtonContainer'>
										<button onClick={cancelModal}>Cancel</button>
									</div>
								</div>
							</div>
						</div>


					</div>
				</> : <></>
			}
		</>
	)
}

export default EmployeeDetailsMobileModal
