import React from 'react';
import './PastRequestCard.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { translateBr } from '../../functions/genericFunctions';

function PastRequestCard(props) {
	let requestDetails = props.requestDetails;


	return (
		<>
			<div className='cardContainer pastRequestCard'>
				<div className='row'>
					<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
						<p className='requestEmployeeName'>{ requestDetails['employee_id']['name'] } ( {requestDetails['employee_id']['employee_id']} ) </p>
						<p>{ translateBr("leave_type") } - {requestDetails['leave_type']}</p>
						<p>{ translateBr("first_leave_date") } - {requestDetails['first_leave_date']}</p>
						<p>{ translateBr("last_leave_date") } - {requestDetails['last_leave_date']}</p>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
						<p>{ translateBr("reason") } - {requestDetails['reason']}</p>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
						<p>{ translateBr("attached_documents") }</p>
						<div className='d-flex requestFileContainer'>
							{
								requestDetails['documents'].map((docLink, index) => {
									return <a key={index} href={docLink} target='_blank'><img src="/icons/svg/file.svg" /></a>
								})
							}
						</div>
						<div className='d-flex requestStatusContainer'>
							{
								requestDetails['status'] === 1 ? 
								<button className='approvedButton'>{ translateBr("approved") }</button> :
								requestDetails['status'] === 2 ? 
								<button className='rejectedButton'>{ translateBr("rejected") }</button> : <></>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PastRequestCard
