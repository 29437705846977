import React from 'react';
import { useRef } from 'react';
import './Reports.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr, formatDate2 } from '../../functions/genericFunctions';
import jsPDF from 'jspdf';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';

registerLocale('pt', pt);

function Reports() {
	const [cookie, setCookie] = useCookies();
	const [employeeReportMonth, setEmployeeReportMonth] = useState(new Date());
	const [employeeReceiptDate, setEmployeeReceiptDate] = useState(new Date());
	const [allEmployeeReportMonth, setAllEmployeeReportMonth] = useState(new Date());
	const [allEmployeesArr, setAllEmployeesArr] = useState([]);
	const [employeeIdReportSelection, setEmployeeIdReportSelection] = useState({});
	const [employeeIdReceiptSelection, setEmployeeIdReceiptSelection] = useState({});

	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const attendanceReceiptDivRef = useRef(null);

	function getAllEmployees() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/employee/getall";
		setShowLoaderScreenFlag(true);

		let options = { headers: { "Authorization": cookie['token'] } }

		axios
			.get(api_endpoint, options)
			.then((response) => {
				let employeesData = JSON.parse(JSON.stringify(response.data.data));
				setAllEmployeesArr(employeesData);
				setEmployeeIdReportSelection(employeesData[0]['_id']);
				setEmployeeIdReceiptSelection(employeesData[0]['_id']);
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				setShowLoaderScreenFlag(false);
			})
	}

	function downloadMonthlyEmployeeReport() {
		console.log("Download Monthly Employee Report");
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/report/each_employee/";
		let dateValue = new Date(employeeReportMonth);
		let year = dateValue.getFullYear();
		let month = dateValue.getMonth() + 1; // Months start at 0!
		console.log(employeeReportMonth);
		let options = { params: { month: month, year: year, employee_id: employeeIdReportSelection }, headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				const href = URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'report.csv'); //or any other extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(href);

				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})
	}

	function downloadAttendanceReceipt() {
		console.log("Download Attendance Receipt");
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/report/attendanceReceipt/";
		let dateValue = new Date(employeeReceiptDate);
		let formattedDate = formatDate2(dateValue);
		console.log(formattedDate);
		let options = { params: { date: formattedDate, employee_id: employeeIdReceiptSelection }, headers: { "Authorization": cookie['token'] } };
		axios
			.get(api_endpoint, options)
			.then((response) => {
				let node = document.getElementById('tmpDiv');
				node.innerHTML = '<h1>Hello World</h1>';
				node.innerHTML = response.data;

				const doc = new jsPDF({
					orientation: "landscape",
					format: 'a4',
					unit: 'px',
				});
				doc.html(attendanceReceiptDivRef.current, {
					async callback(doc) {
						await doc.save(`${employeeIdReceiptSelection}-${formattedDate}.pdf`);
						node.innerHTML = '';
					},
				});
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})
	}

	function downloadMonthlyAttendanceReport() {
		console.log("Download Monthly Attendance Report");
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/report/generate/";
		let dateValue = new Date(allEmployeeReportMonth);
		let year = dateValue.getFullYear();
		let month = dateValue.getMonth() + 1; // Months start at 0!
		console.log(allEmployeeReportMonth);
		let options = { params: { month: month, year: year }, headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				const href = URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'report.csv'); //or any other extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(href);

				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})
	}

	useEffect(() => {
		getAllEmployees();

		return () => {

		}
	}, [])


	return (
		<>
			<div id="reportsPage">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{translateBr("reports")}</h1>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<div className='reportContainer'>
										<h1 className='reportHeading'>Monthly Employee Report</h1>
										<div className='row'>
											<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
												<div className='reportInputContainer'>
													<DatePicker wrapperClassName="monthYearPicker" dateFormat="MMMM yyyy" showMonthYearPicker locale="pt" selected={employeeReportMonth} onChange={(date) => setEmployeeReportMonth(date)} />
												</div>
											</div>
											<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
												<div className='reportInputContainer'>
													<select className='selectReportEmployee' value={employeeIdReportSelection} onChange={(e) => setEmployeeIdReportSelection(e.target.value)}>
														{
															allEmployeesArr.map((employee, index) => {
																return <option key={index} value={employee['_id']}>{employee['name']}</option>
															})
														}
													</select>
												</div>
											</div>
											<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
												<div className='reportInputContainer  d-flex justify-content-start'>
													<button className='downloadButton' onClick={downloadMonthlyEmployeeReport}>Download</button>
												</div>
											</div>

										</div>
									</div>

									<div className='reportContainer'>
										<h1 className='reportHeading'>Employee Attendance Receipt</h1>
										<div className='row'>
											<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
												<div className='reportInputContainer'>
													<DatePicker wrapperClassName="monthYearPicker" dateFormat="dd/MM/yyyy" locale="pt" selected={employeeReceiptDate} onChange={(date) => setEmployeeReceiptDate(date)} />
												</div>
											</div>
											<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
												<div className='reportInputContainer'>
													<select className='selectReportEmployee' value={employeeIdReceiptSelection} onChange={(e) => {
														setEmployeeIdReceiptSelection(e.target.value)
													}}>
														{
															allEmployeesArr.map((employee, index) => {
																return <option key={index} value={employee['_id']}>{employee['name']}</option>
															})
														}
													</select>
												</div>
											</div>
											<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
												<div className='reportInputContainer  d-flex justify-content-start'>
													<button className='downloadButton' onClick={downloadAttendanceReceipt}>Download</button>
												</div>
											</div>

										</div>
									</div>

									<div className='reportContainer'>
										<h1 className='reportHeading'>Monthly Attendance Report</h1>
										<div className='row'>
											<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
												<div className='reportInputContainer'>
													<DatePicker wrapperClassName="monthYearPicker" dateFormat="MMMM yyyy" showMonthYearPicker locale="pt" selected={allEmployeeReportMonth} onChange={(date) => setAllEmployeeReportMonth(date)} />
												</div>
											</div>
											<div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
												<div className='reportInputContainer d-flex justify-content-start'>
													<button className='downloadButton' onClick={downloadMonthlyAttendanceReport}>Download</button>
												</div>
											</div>

										</div>
									</div>




								</div>
							</div>
						</div>
						<br /><br />
					</div>
				</div>
			</div>
			<div id="tmpDiv" ref={attendanceReceiptDivRef}></div>


			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default Reports
