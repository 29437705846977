import React, { useEffect, useState } from 'react';
import './EmployeesClockedOutList.css';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

function EmployeesClockedOutList( props ) {
	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [employeesArr, setEmployeesArr] = useState([]);


	function getClockedOutEmployees() {
		let dateToday = props.date;
		console.log(dateToday);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/attendance/getall/" + dateToday + "/0";
		setShowLoaderScreenFlag(true);

		let options = { headers: { "Authorization": cookie['token'] } }

		axios
			.get(api_endpoint, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				setEmployeesArr(JSON.parse(JSON.stringify(response.data.data)));
			})
			.catch(error => {
				setShowLoaderScreenFlag(false);
			})
	}

	useEffect(() => {
		if (props.date && props.date != "") {
			getClockedOutEmployees();
		}


		return () => {
		}
	}, [props.date])


	return (
		<>
			<div className='listContainer'>
				<table>
					<thead>
						<tr>
							<th>{translateBr("employee")}</th>
							<th>{translateBr("position")}</th>
							<th>{translateBr("timestamp")}</th>
						</tr>
					</thead>
					<tbody>
						{
							employeesArr.map((employee, index) => {
								return (
									<tr key={index}>
										<td>{employee['name']}</td>
										<td>{employee['position']}</td>
										<td>{employee['time_stamp']}</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
				{/* <div className='d-flex flex-row-reverse listActionContainer'>
					<button className='listActionButton nextButton'>Next</button>
					<button className='listActionButton prevButton'>Previous</button>
				</div> */}
			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default EmployeesClockedOutList
