import React from 'react';
import './UpdateShiftModal.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import LoaderScreen from '../LoaderScreen/LoaderScreen';
import { translateBr, convert12HourTimeTo24HourFormat } from '../../functions/genericFunctions';

function UpdateShiftModal(props) {
	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);

	const [shiftId, setShiftId] = useState("");
	const [shiftName, setShiftName] = useState("");
	const [shiftStartTime, setShiftStartTime] = useState("00:00:00");
	const [shiftEndTime, setShiftEndTime] = useState("00:00:00");
	const [shiftIntervalStartTime, setShiftIntervalStartTime] = useState("00:00:00");
	const [shiftIntervalEndTime, setShiftIntervalEndTime] = useState("00:00:00");
	const [shiftActiveDaysArr, setShiftActiveDaysArr] = useState([0, 0, 0, 0, 0, 0, 0]);
	const [shiftActiveDays, setShiftActiveDays] = useState([]);

	function updateShiftActiveDaysArr(index, value) {
		let shiftActiveDaysArrTemp = JSON.parse(JSON.stringify(shiftActiveDaysArr));
		shiftActiveDaysArrTemp[index] = Number(value);
		setShiftActiveDaysArr(JSON.parse(JSON.stringify(shiftActiveDaysArrTemp)));
		let activeDaysArr = [];
		for (let i = 0; i < shiftActiveDaysArrTemp.length; i++) {
			if (shiftActiveDaysArrTemp[i] === 1) {
				activeDaysArr.push(i);
			}
		}
		setShiftActiveDays(activeDaysArr);
	}

	function updateShiftSubmit() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/shift/update/"+shiftId;

		setShowLoaderScreenFlag(true);
		let body = {
			name: shiftName,
			start_time: shiftStartTime,
			end_time: shiftEndTime,
			days_of_week: shiftActiveDays,
			interval_start: shiftIntervalStartTime,
			interval_end: shiftIntervalEndTime,
		}

		let options = { headers: { "Authorization": cookie['token'] } }

		console.log(body);
		axios
			.post(api_endpoint, JSON.parse(JSON.stringify(body)), options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				console.log(response.data);
				props.getAllShifts();
				props.toggleUpdateShiftModalState();
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	function deleteShiftSubmit(){
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/shift/delete/"+shiftId;
		setShowLoaderScreenFlag(true);
		let options = { headers: { "Authorization": cookie['token'] } }
		axios
			.delete(api_endpoint, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				console.log(response.data);
				props.getAllShifts();
				props.toggleUpdateShiftModalState();
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	useEffect(() => {
		console.log(props.updateShiftDetails);
		if (props.updateShiftDetails != {} & props.updateShiftDetails != "" & props.updateShiftDetails != null) {
			setShiftId(props.updateShiftDetails['_id']);
			setShiftName(props.updateShiftDetails['name']);
			setShiftStartTime(convert12HourTimeTo24HourFormat(props.updateShiftDetails['start_time']));
			setShiftEndTime(convert12HourTimeTo24HourFormat(props.updateShiftDetails['end_time']));
			setShiftIntervalStartTime(convert12HourTimeTo24HourFormat(props.updateShiftDetails['interval_start']));
			setShiftIntervalEndTime(convert12HourTimeTo24HourFormat(props.updateShiftDetails['interval_end']));
			setShiftActiveDays(props.updateShiftDetails['days_of_week']);

			let shiftActiveDaysArrTemp = JSON.parse(JSON.stringify(shiftActiveDaysArr));
			for (let i = 0; i < props.updateShiftDetails['days_of_week'].length; i++) {
				shiftActiveDaysArrTemp[props.updateShiftDetails['days_of_week'][i]] = 1;
			}
			setShiftActiveDaysArr(JSON.parse(JSON.stringify(shiftActiveDaysArrTemp)));
			// console.log(shiftActiveDaysArrTemp);

		}
		return () => {

		}
	}, [props.updateShiftDetails])


	return (
		<>
			{
				props.updateShiftModalState ? <>
					<div id="AddShiftModal" className='modalContainer AddShiftModal'>
						<div className='AddShiftModalArea'>
							<div className='closeModal' onClick={props.toggleUpdateShiftModalState}>
								<img src="/icons/svg/close-1.svg" />
							</div>
							<div className='row'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
									<div className='d-flex justify-content-center'>
										<h1>{translateBr("edit_shift")}</h1>
									</div>

								</div>
							</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 addShiftForm'>

								<div className='row'>
									<div className='col-12'>
										<div className='addShiftFormInputContainer'>
											<p>{translateBr("shift_name")}</p>
											<input className='formTextInput' type="text" placeholder='Vespertino' value={shiftName} onChange={(e) => setShiftName(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{translateBr("start-time")}</p>
											<input className='formTextInput' type="time" value={shiftStartTime} onChange={(e) => setShiftStartTime(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{translateBr("end-time")}</p>
											<input className='formTextInput' type="time" value={shiftEndTime} onChange={(e) => setShiftEndTime(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{translateBr("shift_interval_start_time")}</p>
											<input className='formTextInput' type="time" value={shiftIntervalStartTime} onChange={(e) => setShiftIntervalStartTime(e.target.value)} />
										</div>
									</div>
									<div className='col-6'>
										<div className='addShiftFormInputContainer'>
											<p>{translateBr("shift_interval_end_time")}</p>
											<input className='formTextInput' type="time" value={shiftIntervalEndTime} onChange={(e) => setShiftIntervalEndTime(e.target.value)} />
										</div>
									</div>
									<div className='col-12'>
										<div className='addShiftFormInputContainer'>
											<p>{translateBr("days")}</p>
											<div className='d-flex addShiftFormActiveDaysCheckboxesContainer'>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="mon" name="mon" value="mon" onChange={(e) => { updateShiftActiveDaysArr(0, e.target.checked) }} checked={shiftActiveDaysArr[0]} />
													<label className='checkboxLabel' htmlFor="mon">{translateBr("mon")}</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="tue" name="tue" value="tue" onChange={(e) => { updateShiftActiveDaysArr(1, e.target.checked) }} checked={shiftActiveDaysArr[1]} />
													<label className='checkboxLabel' htmlFor="tue">{translateBr("tue")}</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="wed" name="wed" value="wed" onChange={(e) => { updateShiftActiveDaysArr(2, e.target.checked) }} checked={shiftActiveDaysArr[2]} />
													<label className='checkboxLabel' htmlFor="wed">{translateBr("wed")}</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="thu" name="thu" value="thu" onChange={(e) => { updateShiftActiveDaysArr(3, e.target.checked) }} checked={shiftActiveDaysArr[3]} />
													<label className='checkboxLabel' htmlFor="thu">{translateBr("thu")}</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="fri" name="fri" value="fri" onChange={(e) => { updateShiftActiveDaysArr(4, e.target.checked) }} checked={shiftActiveDaysArr[4]} />
													<label className='checkboxLabel' htmlFor="fri">{translateBr("fri")}</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="sat" name="sat" value="sat" onChange={(e) => { updateShiftActiveDaysArr(5, e.target.checked) }} checked={shiftActiveDaysArr[5]} />
													<label className='checkboxLabel' htmlFor="sat">{translateBr("sat")}</label><br />
												</div>
												<div className='checkboxContainer'>
													<input className='checkboxInput' type="checkbox" id="sun" name="sun" value="sun" onChange={(e) => { updateShiftActiveDaysArr(6, e.target.checked) }} checked={shiftActiveDaysArr[6]} />
													<label className='checkboxLabel' htmlFor="sun">{translateBr("sun")}</label><br />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='row'>
									<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
										<div className='d-flex justify-content-start updateShiftFormActionContainer'>
											<button className='deleteButton' onClick={deleteShiftSubmit}>{translateBr("delete")}</button>
										</div>
										<br/>
									</div>
									<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
										<div className='d-flex justify-content-end updateShiftFormActionContainer'>
											<button className='cancelButton' onClick={props.toggleUpdateShiftModalState}>{translateBr("cancel")}</button>
											<button className='updateButton' onClick={updateShiftSubmit}>{translateBr("save")}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</> : <>
				</>
			}

			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default UpdateShiftModal
