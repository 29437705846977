import React from 'react';
import './Timesheet.css';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { translateBr } from '../../functions/genericFunctions';

import TimesheetEmployeesListContainer from '../../components/TimesheetEmployeesListContainer/TimesheetEmployeesListContainer';
import EmployeeTimestampTableContainer from '../../components/EmployeeTimestampTableContainer/EmployeeTimestampTableContainer';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';

function Timesheet() {
	const [cookie, setCookie] = useCookies();
	const [allEmployees, setAllEmployees] = useState([]);
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(-1);

	let todayDateTemp = formatDate((new Date()).toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));

	function formatDate(date) {
		let value = new Date(date);
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		return formattedDate;
	}

	function getAllEmployees() {
		setShowLoaderScreenFlag(true);

		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/attendance/employstatus/" + todayDateTemp;
		let options = { headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {
				let allEmployeesArr = response.data.data;
				if (allEmployeesArr.length > 0) {
					for (let i = 0; i < allEmployeesArr.length; i++) {
						allEmployeesArr[i]['selectFlag'] = false;
					}
					setAllEmployees(JSON.parse(JSON.stringify(allEmployeesArr)));
					setSelectedEmployeeIndex(0);
				}
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
			})
	}

	useEffect(() => {
		getAllEmployees();
		return () => {

		}
	}, [])

	// AIzaSyCAwHD6jCZNwvrkpzi17gzrbXQpf1KQRYA

	return (
		<>
			<div id="timesheet-page">
				<div className="mainContentContainer">
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='pageTitleContainer'>
									<h1>{translateBr("timesheet")}</h1>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
								<div className='cardContainer'>
									<div className='employeeListContainer'>
										<TimesheetEmployeesListContainer allEmployees={allEmployees} setSelectedEmployeeIndex={setSelectedEmployeeIndex} selectedEmployeeIndex={selectedEmployeeIndex} />
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
								<div className='cardContainer EmployeeTimestampTableContainerCard'>
									<EmployeeTimestampTableContainer selectedEmployeeIndex={selectedEmployeeIndex} allEmployees={allEmployees} setShowLoaderScreenFlag={setShowLoaderScreenFlag} />
								</div>
							</div>
						</div>
						<br /><br />
					</div>
				</div>
			</div>
			{
				showLoaderScreenFlag ? <>
					<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
				</> : <></>
			}

		</>
	)
}

export default Timesheet
