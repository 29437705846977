import React from 'react';
import './NavbarOption.css';
import { useNavigate } from 'react-router-dom';

function NavbarOption(props) {
	const navigate = useNavigate();
	function redirectPage() {
		props.navigateFunction(props.id, props.link);
	}

	return (
		<>

			{
				props.desktopNavbarState || props.type === "mobile" ? <>
					<div key={props.id} className={props.active ? "navbarOptionContainer navbarActive" : "navbarOptionContainer"} onClick={redirectPage}>
						<div className='row'>
							<div className='col-9'>
								<div className='navbarOptionTitle'>
									<img src={props.iconPath} /><h1>{props.title}</h1>
								</div>
							</div>
							<div className='col-3'>
								<div className='expandButtonArea'>
									<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12.5 8.75L18.75 15L12.5 21.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>

								</div>
							</div>
						</div>
					</div>
				</> : <>
					<div className='collapsedNavbarOptionContainer' onClick={redirectPage}>
						<img src={props.iconPath} />
					</div>
				</>
			}
		</>
	)
}

export default NavbarOption
