import React, { useEffect, useState } from 'react';
import './EditEmployeeModal.css';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr } from '../../functions/genericFunctions';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt);

function EditEmployeeModal(props) {
	const inputProfilePhotoFileRef = React.useRef();
	const profilePhotoRef = React.useRef();

	const salaryInputRef = React.useRef();
	const phoneNumberInputRef = React.useRef();
	const emergencyPhoneNumberInputRef = React.useRef();
	const [salaryInputActiveFlag, setSalaryInputActiveFlag] = useState(false);
	const [phoneNumberInputActiveFlag, setPhoneNumberInputActiveFlag] = useState(false);
	const [emergencyPhoneNumberInputActiveFlag, setEmergencyPhoneNumberInputActiveFlag] = useState(false);
	const [ dateOfBirthInput, setDateOfBirthInput ] = useState( new Date() );
	const [ dateOfEmploymentInput, setDateOfEmploymentInput ] = useState( new Date() );

	const [cookie, setCookie] = useCookies();
	const [apiResponseText, setApiResponseText] = useState("");
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [profilePhotoObj, setProfilePhotoObject] = useState(null);
	const [employeeFormData, setEmployeeFormData] = useState({
		'login_id': '',
		'password': '',
		'name': '',
		'rg_number': '',
		'cpf_number': '',
		'civil_status': 1,
		'address': '',
		'country_code': '+55',
		'phone_number': '',
		'emergency_contact_person': '',
		'emergency_contact_number': '',
		'number_of_children': '',
		'employment_date': '',
		'ct_number': '',
		'notes': '',
		'remote_employee': false,
		'shift_id': '',
		'contract_id': '-',
		'team_id': '',
		'position_id': '',
		'card_id': '',
		'image': null,
		'currency': 'BRL',
		'monthly_salary': '',
		'business_unit': '',
		'date_of_birth': '',
	});

	let employee_id = props.selectedEmployeeDetails['_id'];

	const [employeeUpdates, setEmployeeUpdates] = useState({});

	let allShiftsArr = props.allShifts;

	function updateEmployeeObj(key, value) {
		if (key === 'date_of_birth' || key === 'employment_date') {
			console.log(value);
			let dateValue = new Date(value);
			let yyyy = dateValue.getFullYear();
			let mm = dateValue.getMonth() + 1; // Months start at 0!
			let dd = dateValue.getDate();
			if (dd < 10) dd = '0' + dd;
			if (mm < 10) mm = '0' + mm;
			let formattedDate = yyyy + '-' + mm + '-' + dd;
			value = formattedDate;
			if ( key === 'date_of_birth' ){
				setDateOfBirthInput(dateValue);
			}
			else if ( key === 'employment_date' ){
				setDateOfEmploymentInput(dateValue);
			}
		}
		else if (key === "login_id") {
			return;
		}

		let employeeUpdatesTemp = JSON.parse(JSON.stringify(employeeUpdates));
		employeeUpdatesTemp[key] = value;
		if (key === "emergency_contact_number" || key === "phone_number") {
			employeeUpdatesTemp['country_code'] = employeeFormData['country_code'];
		}
		setEmployeeUpdates(JSON.parse(JSON.stringify(employeeUpdatesTemp)));
		updateEmployeeFormKey(key, value);
	}

	function updateEmployeeFormKey(key, value) {
		if (key === 'date_of_birth' || key === 'employment_date') {
			let dateValue = new Date(value);
			let yyyy = dateValue.getFullYear();
			let mm = dateValue.getMonth() + 1; // Months start at 0!
			let dd = dateValue.getDate();
			if (dd < 10) dd = '0' + dd;
			if (mm < 10) mm = '0' + mm;
			let formattedDate = yyyy + '-' + mm + '-' + dd;
			value = formattedDate;
		}
		let employeeFormTemp = JSON.parse(JSON.stringify(employeeFormData));
		employeeFormTemp[key] = value;
		setEmployeeFormData(JSON.parse(JSON.stringify(employeeFormTemp)));
	}

	function datePickerChange(value){
		console.log(value);
	}

	function updateSalaryInput(input) {
		const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
		let validSalaryInput = input.match(regex)[0];
		updateEmployeeObj('monthly_salary', validSalaryInput);
	}

	function formatSalaryInput(input) {
		var parts = input.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}

	function updatePhoneNumberInput(input) {
		if (input.length <= 11) {
			updateEmployeeObj('phone_number', input);
		}

	}

	function updateEmergencyPhoneNumberInput(input) {
		if (input.length <= 11) {
			console.log(input);
			updateEmployeeObj('emergency_contact_number', input);
		}
	}

	function formatPhoneNumberInput(input) {
		// console.log(input);
		try {
			input = input.toString();
			if (input.length > 0) {
				input = "(" + input;
				// console.log(input);
				if (input.length > 2) {
					input = input.slice(0, 3) + ") " + input.slice(3);
				}
				if (input.length > 9 && input.length <= 13) {
					input = input.slice(0, 9) + " " + input.slice(9);
				}
				else if (input.length > 13) {
					input = input.slice(0, 10) + " " + input.slice(10);
				}
				return input;
			}
		} catch (error) {
			console.log(error);
			return "";
		}
		return "";
	}

	function formatEmergencyPhoneNumberInput( input ){
		try {
			input = input.toString();
			if (input.length > 0) {
				input = "(" + input;
				if (input.length > 2) {
					input = input.slice(0, 3) + ") " + input.slice(3);
				}
				if (input.length > 9 && input.length <= 13) {
					input = input.slice(0, 9) + " " + input.slice(9);
				}
				else if (input.length > 13) {
					input = input.slice(0, 10) + " " + input.slice(10);
				}
				return input;
			}
		} catch (error) {
			return "";
		}
		return "";
	}

	function updateEmployeeSubmit() {
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/employee/update/" + employee_id;
		setShowLoaderScreenFlag(true);
		let bodyFormData = new FormData();

		for (var key in employeeUpdates) {
			if (key === 'image') {

			}
			else {
				bodyFormData.append(key, employeeUpdates[key]);
			}
		}

		if (profilePhotoObj != null) {
			bodyFormData.append('image', profilePhotoObj);
		}

		bodyFormData.forEach(function (value, key) {
			console.log(key, value);
		});

		let options = { headers: { "Authorization": cookie['token'], "Content-Type": "multipart/form-data" } }

		axios
			.post(api_endpoint, bodyFormData, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				let responseMsg = response.data.message;
				console.log(response.data);
				setApiResponseText( translateBr("employee_updated_successfully!") );
				props.getAllEmployees();
				setTimeout(() => {
					props.toggleEditEmployeeModal();
				}, 500);
			})
			.catch(error => {
				console.log(error.response.data);
				setShowLoaderScreenFlag(false);
				setApiResponseText( translateBr("employee_could_not_be_updated,_check_entered_form_details_again!") );
			})
	}

	function onProfilePhotoChange(e) {
		// console.log(e.target.files);
		updateEmployeeFormKey('image', e.target.files[0]);
		let profilePhotoFileURL = URL.createObjectURL(e.target.files[0]);
		profilePhotoRef.current.src = profilePhotoFileURL;
		updateEmployeeObj('image', e.target.files[0]);
		setProfilePhotoObject(e.target.files[0]);
	}


	function formatDate(date) {
		let value = new Date(date);
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		return formattedDate;
	}

	useEffect(() => {
		// setShowLoaderScreenFlag(false);
	
		return () => {
		}
	}, [])
	

	useEffect(() => {
		let employeeFormDataTemp = {
			'employee_id': props.selectedEmployeeDetails['employee_id'],
			'login_id': props.selectedEmployeeDetails['login_id'],
			'password': "********",
			'name': props.selectedEmployeeDetails['name'],
			'rg_number': props.selectedEmployeeDetails['rg_number'],
			'cpf_number': props.selectedEmployeeDetails['cpf_number'],
			'civil_status': props.selectedEmployeeDetails['civil_status'],
			'address': props.selectedEmployeeDetails['address'],
			'country_code': props.selectedEmployeeDetails['phone_number']['country_code'],
			'phone_number': props.selectedEmployeeDetails['phone_number']['number'],
			'emergency_contact_person': props.selectedEmployeeDetails['emergency_contact_person'],
			'emergency_contact_number': props.selectedEmployeeDetails['emergency_contact_number']['number'],
			'number_of_children': props.selectedEmployeeDetails['number_of_children'],
			'employment_date': formatDate(props.selectedEmployeeDetails['employment_date']),
			'ct_number': props.selectedEmployeeDetails['ct_number'],
			'notes': props.selectedEmployeeDetails['notes'],
			'remote_employee': props.selectedEmployeeDetails['remote_employee'],
			'shift_id': props.selectedEmployeeDetails['shift_id'],
			'contract_id': props.selectedEmployeeDetails['contract_id'],
			'team_id': props.selectedEmployeeDetails['team_id'],
			'position_id': props.selectedEmployeeDetails['position_id'],
			'card_id': props.selectedEmployeeDetails['card']['card_id'],
			'image': "",
			'currency': props.selectedEmployeeDetails['monthly_salary']['currency'],
			'monthly_salary': props.selectedEmployeeDetails['monthly_salary']['salary'],
			'business_unit': props.selectedEmployeeDetails['business_unit'],
			'date_of_birth': formatDate(props.selectedEmployeeDetails['date_of_birth']),
		}
		setEmployeeFormData(JSON.parse(JSON.stringify(employeeFormDataTemp)));
		if (props.selectedEmployeeDetails['picture'] && props.selectedEmployeeDetails['picture'] != "") {
			profilePhotoRef.current.src = props.selectedEmployeeDetails['picture'];
		}

		return () => {

		}
	}, [props.selectedEmployeeDetails])


	return (
		<>
			<div id="EditEmployeeModal" className='modalContainer'>

				<div className='EditEmployeeCardContainer'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<div className='cardContainer EditEmployeeModalArea'>
									<div className='closeModal' onClick={props.toggleEditEmployeeModal} >
										<img src="/icons/svg/close-1.svg" />
									</div>
									<div className='row employeeIdRow'>
										<div className='col-12'>
											<p>{ translateBr("employee_id") } - {employeeFormData['employee_id']}</p>
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
											<div className='d-flex justify-content-center'>
												<div className="modalProfilePhotoContainer">
													<div className='editProfilePhotoButton' onClick={() => { inputProfilePhotoFileRef.current.click() }}>
														<img src="/icons/svg/Edit-Button.svg" />
														<input hidden ref={inputProfilePhotoFileRef} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => { onProfilePhotoChange(e) }} />
													</div>
													<div className='photoArea'>
														<img ref={profilePhotoRef} src="/images/Profile-Photo.svg" />
													</div>

												</div>
											</div>

										</div>

										<div className='col-lg-9 col-md-9 col-sm-12 col-xs-12'>
											<div className='inputFormContainer'>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("full_name") }</h1>
													<input type='text' value={employeeFormData['name']} onChange={(e) => updateEmployeeObj('name', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("business_unit") }</h1>
													<input type='text' value={employeeFormData['business_unit']} onChange={(e) => updateEmployeeObj('business_unit', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("team") }</h1>
													<input type='text' value={employeeFormData['team_id']} onChange={(e) => updateEmployeeObj('team_id', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("position") }</h1>
													<input type='text' value={employeeFormData['position_id']} onChange={(e) => updateEmployeeObj('position_id', e.target.value)} />
												</div>
												<div className='row'>
													<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
														<div className='inputContainer EditEmployeeFormInputContainer'>
															<h1>{ translateBr("date_of_birth") }</h1>
															{/* <input type='date' value={employeeFormData['date_of_birth']} onChange={(e) => updateEmployeeObj('date_of_birth', e.target.value)} /> */}
															<DatePicker dateFormat="dd/MM/yyyy" locale="pt"  onChange={(date) => updateEmployeeObj('date_of_birth', date)} selected={ dateOfBirthInput } className="dateInput" wrapperClassName="dateInputWrapper" />
														</div>
													</div>
													<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
														<div className='inputContainer EditEmployeeFormInputContainer'>
															<h1>{ translateBr("employment_date") }</h1>
															{/* <input type='date' value={employeeFormData['employment_date']} onChange={(e) => updateEmployeeObj('employment_date', e.target.value)} /> */}
															<DatePicker dateFormat="dd/MM/yyyy" locale="pt"  onChange={(date) => updateEmployeeObj('employment_date', date)} selected={ dateOfEmploymentInput } className="dateInput" wrapperClassName="dateInputWrapper" />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
											<div className='inputFormContainer'>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("rg_number") }</h1>
													<input type='text' value={employeeFormData['rg_number']} onChange={(e) => updateEmployeeObj('rg_number', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("cpf_number") }</h1>
													<input type='text' value={employeeFormData['cpf_number']} onChange={(e) => updateEmployeeObj('cpf_number', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("civil_status") }</h1>
													<select value={employeeFormData['civil_status']} onChange={(e) => updateEmployeeObj('civil_status', e.target.value)} >
														<option value={1}>{ translateBr("married") }</option>
														<option value={0}>{ translateBr("unmarried") }</option>
													</select>
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("number_of_children") }</h1>
													<input type='text' value={employeeFormData['number_of_children']} onChange={(e) => updateEmployeeObj('number_of_children', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("ct_number") }</h1>
													<input type='text' value={employeeFormData['ct_number']} onChange={(e) => updateEmployeeObj('ct_number', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("shift") }</h1>
													<select value={employeeFormData['shift_id']} onChange={(e) => updateEmployeeObj('shift_id', e.target.value)} >
														{
															allShiftsArr.map((shift, index) => {
																return <option key={index} value={shift['_id']}>{shift['name']}</option>
															})
														}
													</select>
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("login_id") }</h1>
													<input type='text' value={employeeFormData['login_id']} onChange={(e) => updateEmployeeObj('login_id', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("remote_employee") }</h1>
													<select onChange={(e) => updateEmployeeObj('remote_employee', e.target.value)} >
														<option value={false}>{ translateBr("no") }</option>
														<option value={true}>{ translateBr("yes") }</option>
													</select>
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("card_id") }</h1>
													<input type='text' value={employeeFormData['card_id']} onChange={(e) => updateEmployeeObj('card_id', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("password") }</h1>
													<input type='text' value={employeeFormData['password']} onChange={(e) => updateEmployeeObj('password', e.target.value)} placeholder='********' />
												</div>
											</div>
										</div>
										<div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
											<div className='inputFormContainer'>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("address") }</h1>
													<textarea rows="4" value={employeeFormData['address']} onChange={(e) => updateEmployeeObj('address', e.target.value)} ></textarea>
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("phone_number") }</h1>

													<div className='salaryInputContainer'>
														<input onClick={() => { phoneNumberInputRef.current.focus(); }} type='text' className={phoneNumberInputActiveFlag ? 'amountTextFocused amountText' : 'amountText'} value={formatPhoneNumberInput(employeeFormData['phone_number'])} onChange={(e) => { }} />
														<input ref={phoneNumberInputRef} type="text" className='amountTextInput' value={employeeFormData['phone_number']} onChange={(e) => updatePhoneNumberInput(e.target.value)} onFocus={() => { setPhoneNumberInputActiveFlag(true) }} onBlur={() => { setPhoneNumberInputActiveFlag(false) }} />
													</div>


												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("emergency_contact_name") }</h1>
													<input type='text' value={employeeFormData['emergency_contact_person']} onChange={(e) => updateEmployeeObj('emergency_contact_person', e.target.value)} />
												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("emergency_contact_number") }</h1>

													<div className='salaryInputContainer'>
														<input onClick={() => { emergencyPhoneNumberInputRef.current.focus(); }} type='text' className={emergencyPhoneNumberInputActiveFlag ? 'amountTextFocused amountText' : 'amountText'} value={formatEmergencyPhoneNumberInput(employeeFormData['emergency_contact_number'])} onChange={(e) => { }} />
														<input ref={emergencyPhoneNumberInputRef} type="text" className='amountTextInput' value={employeeFormData['emergency_contact_number']} onChange={(e) => updateEmergencyPhoneNumberInput(e.target.value)} onFocus={() => { setEmergencyPhoneNumberInputActiveFlag(true) }} onBlur={() => { setEmergencyPhoneNumberInputActiveFlag(false) }} />
													</div>

												</div>
												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("monthly_salary_(_brl_)") }</h1>
													{/* <input type='text' value={employeeFormData['monthly_salary']} onChange={(e) => updateEmployeeObj('monthly_salary', e.target.value)} /> */}
													<div className='salaryInputContainer'>
														<input onClick={() => { salaryInputRef.current.focus(); }} type='text' className={salaryInputActiveFlag ? 'amountTextFocused amountText' : 'amountText'} value={formatSalaryInput(employeeFormData['monthly_salary'])} onChange={(e) => { }} />
														<input ref={salaryInputRef} type="text" className='amountTextInput' value={employeeFormData['monthly_salary']} onChange={(e) => updateSalaryInput(e.target.value)} onFocus={() => { setSalaryInputActiveFlag(true) }} onBlur={() => { setSalaryInputActiveFlag(false) }} />
													</div>
												</div>

												<div className='inputContainer EditEmployeeFormInputContainer'>
													<h1>{ translateBr("notes") }</h1>
													<textarea rows="8" value={employeeFormData['notes']} onChange={(e) => updateEmployeeObj('notes', e.target.value)}></textarea>
												</div>

											</div>
										</div>
										<div className='col-12'>
											<p className='apiResponseText'>{apiResponseText}</p>
											<div className='d-flex justify-content-end EditEmployeeFormActionContainer'>
												<button className='cancelButton' onClick={props.toggleEditEmployeeModal}>{ translateBr("cancel") }</button>
												<button className='saveButton' onClick={updateEmployeeSubmit}>{ translateBr("save") }</button>
											</div>
										</div>
									</div>
									<br />
								</div>
							</div>
						</div>

					</div>
					<br /><br />
				</div>


			</div>
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default EditEmployeeModal
