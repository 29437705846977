import React, { useEffect } from 'react';
import './EmployeeTableContainer.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { translateBr } from '../../functions/genericFunctions';

function EmployeeTableContainer(props) {
	const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(0);
	let allEmployees = props.allEmployees;

	function selectEmployee(index, details) {
		setSelectedEmployeeIndex(index);
		props.setSelectedEmployeeDetails(details);
		props.openMobileModal();
	}

	useEffect(() => {
		return () => {
		}
	}, [allEmployees])


	return (
		<>
			<div id="employeeTableContainer" className='employeesTableContainer'>
				<table>
					<colgroup>
						<col span="1" style={{ width: "50%" }} />
						<col span="1" style={{ width: "30%" }} />
						<col span="1" style={{ width: "20%" }} />
					</colgroup>
					<thead>
						<tr>
							<th>{ translateBr("name") }</th>
							<th>{ translateBr("employee_id") }</th>
							<th>{ translateBr("status") }</th>
						</tr>
					</thead>
					<tbody>
						{
							allEmployees.map((employee, index) => {
								return (
									<tr key={index} className={index === selectedEmployeeIndex ? 'activeRow' : ''} onClick={() => { selectEmployee(index, employee); }}>
										<td>{employee['name']}</td>
										<td>{employee['employee_id']}</td>
										<td>
											{
												employee['status'] === 0 ? <>
													<button className='employeeStatusButton clockedOutStatus'>
														{ translateBr("clocked_out") }
													</button>
												</> : employee['status'] === 1 ? <>
													<button className='employeeStatusButton clockedInStatus'>
														{ translateBr("clocked_in") }
													</button>
												</> : employee['status'] === 2 ? <>
													<button className='employeeStatusButton onLeaveStatus'>
														{ translateBr("on_leave") }
													</button>
												</> : employee['status'] === 3 ? <>
													<button className='employeeStatusButton unknownStatus'>
														{ translateBr("unknown") }
													</button>
												</> : <>
												</>
											}

										</td>
									</tr>
								)
							})
						}
						{/* <tr className='activeRow'>
							<td>Alok Ranjan</td>
							<td>9826311996</td>
							<td>
								<button className='employeeStatusButton activeStatus'>
									Active
								</button>
							</td>
						</tr>
						<tr className=''>
							<td>Alok Ranjan</td>
							<td>9826311996</td>
							<td>
								<button className='employeeStatusButton onleaveStatus'>
									On Leave
								</button>
							</td>
						</tr>
						<tr className=''>
							<td>Alok Ranjan</td>
							<td>9826311996</td>
							<td>
								<button className='employeeStatusButton inactiveStatus'>
									Inactive
								</button>
							</td>
						</tr> */}
					</tbody>
				</table>
				{/* <div className='d-flex flex-row-reverse listActionContainer'>
					<button className='listActionButton nextButton'>Next</button>
					<button className='listActionButton prevButton'>Previous</button>
				</div> */}
			</div>
		</>
	)
}

export default EmployeeTableContainer
