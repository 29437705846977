import React from "react";
import "./LoaderScreen.css";
// import Loader from "react-js-loader";
import { Triangle, Grid } from 'react-loader-spinner'

function LoaderScreen(props) {

	return (
		<>
			{props.showLoaderScreenFlag ? (
				<>
					<div className="loaderScreenContainer">
						<div className="loaderContainer">
							<Grid
								height="80"
								width="80"
								color="#FFD600"
								wrapperClass="loaderArea"
								visible={true}
							/>
						</div>
					</div>

				</>
			) : (
				<></>
			)}
		</>
	);
}

export default LoaderScreen;
