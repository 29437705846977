import React from 'react';
import './EmployeeTimestampTableContainer.css';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import LoaderScreen from '../../components/LoaderScreen/LoaderScreen';
import { translateBr, pad } from '../../functions/genericFunctions';
import MapLocationModal from '../MapLocationModal/MapLocationModal';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';


registerLocale('pt', pt);


function EmployeeTimestampTableContainer(props) {
	const [cookie, setCookie] = useCookies();
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDateEpoch, setSelectedDateEpoch] = useState(0);
	const [showLoaderScreenFlag, setShowLoaderScreenFlag] = useState(false);
	const [timesheetData, setTimesheetData] = useState([]);
	const [employeeLocationData, setEmployeeLocationData] = useState({});
	const [mapLocationModalState, setMapLocationModalState] = useState(false);
	const [employeeDetails, setEmployeeDetails] = useState({});
	const [attendanceCreateFlag, setAttendanceCreateFlag] = useState(false);
	const [attendanceCreateObj, setAttendanceCreateObj] = useState({ "epoch": 0, "type": 1, "timestring": "00:00:00" });

	function formatDate(date) {
		let value = new Date(date);
		let yyyy = value.getFullYear();
		let mm = value.getMonth() + 1;
		let dd = value.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		let formattedDate = yyyy + '-' + mm + '-' + dd;
		return formattedDate;
	}

	function formatHourMinuteFromEpoch(epoch) {
		let utcSeconds = epoch;
		let d = (new Date(0));
		d.setUTCSeconds(utcSeconds);
		let dateObj = new Date(d.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));
		let hour = dateObj.getHours()
		let minutes = dateObj.getMinutes()
		let seconds = dateObj.getSeconds();
		let formattedTime = pad(hour, 2) + ':' + pad(minutes, 2) + ':00';
		return formattedTime;
	}

	function setSelectedDateValues(dateTemp) {
		// console.log(dateTemp);
		setSelectedDate(dateTemp);
		let d = new Date(dateTemp);
		// console.log(d);
		// console.log( ( d.getTime() / 1000 ) + 3*3600 );
		let epochOfDate = (d.getTime() / 1000) + 3 * 3600;
		setSelectedDateEpoch(epochOfDate);

	}

	function selectDate(e) {
		console.log(e);
		// let dateTemp = formatDate(new Date(e.target.value)).toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
		setSelectedDateValues(e);
	}

	function getEmployeeTimesheet(employeeId, date) {
		setShowLoaderScreenFlag(true);
		let dateTemp = formatDate(new Date(date)).toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });

		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/attendance/" + employeeId + "/" + dateTemp;
		let options = { headers: { "Authorization": cookie['token'] } }
		axios
			.get(api_endpoint, options)
			.then((response) => {

				let timeSheetDataTemp = JSON.parse(JSON.stringify(response.data.data));
				for (let i = 0; i < timeSheetDataTemp.length; i++) {
					timeSheetDataTemp[i]['updateFlag'] = false;
					timeSheetDataTemp[i]['updatedEpoch'] = timeSheetDataTemp[i]['epoch'];
					timeSheetDataTemp[i]['timeHourMinuteString'] = formatHourMinuteFromEpoch(timeSheetDataTemp[i]['epoch']);
				}
				// console.log(timeSheetDataTemp);
				setTimesheetData(JSON.parse(JSON.stringify(timeSheetDataTemp)));
				setShowLoaderScreenFlag(false);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})
	}

	function addAttendance() {
		setAttendanceCreateFlag(true);
	}

	function editAttendance(attendanceObj) {
		// console.log("Edit Attendance");
		// console.log(attendanceObj);
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/attendance/edit";
		let bodyFormData = new FormData();
		bodyFormData.append('attendance_id', attendanceObj['_id']);
		bodyFormData.append('epoch', attendanceObj['updatedEpoch']);
		bodyFormData.append('type', attendanceObj['type']);

		let options = { headers: { "Authorization": cookie['token'] }, "Content-Type": "multipart/form-data" }
		axios
			.post(api_endpoint, bodyFormData, options)
			.then((response) => {
				// console.log(response);
				setShowLoaderScreenFlag(false);
				// console.log(employeeDetails);
				getEmployeeTimesheet(employeeDetails['id'], selectedDate);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})

	}

	function cancelEmployeeAttendanceCreation() {
		setAttendanceCreateFlag(false);
	}

	function updateAttendanceCreateObj(e, key) {
		let tempAttendanceCreateObj = JSON.parse(JSON.stringify(attendanceCreateObj));
		if (key === "type") {
			tempAttendanceCreateObj['type'] = e.target.value;
			setAttendanceCreateObj(JSON.parse(JSON.stringify(tempAttendanceCreateObj)));
		}
		else if (key === "timestamp") {
			// console.log(e.target.value);
			// "epoch": 0, "type": 1, "timestring": "00:00:00"
			let inputTimeString = e.target.value;
			let splitedTime = inputTimeString.split(':');
			let inputTimeHours = parseInt(splitedTime[0]);
			let inputTimeMinutes = parseInt(splitedTime[1]);
			let targetSecondsPassed = inputTimeHours * 3600 + inputTimeMinutes * 60;
			let newAttendanceEpoch = selectedDateEpoch + targetSecondsPassed;
			tempAttendanceCreateObj['epoch'] = newAttendanceEpoch;
			tempAttendanceCreateObj['timestring'] = e.target.value + ":00";
			setAttendanceCreateObj(JSON.parse(JSON.stringify(tempAttendanceCreateObj)));
		}

	}

	function createEmployeeAttendance() {
		let attendanceCreateObjTemp = JSON.parse(JSON.stringify(attendanceCreateObj));
		// Call Admin Attendance API
		setShowLoaderScreenFlag(true);
		let api_endpoint = process.env.REACT_APP_API_BASE_URL + "/attendance/foradmin";
		let employeeCardId = employeeDetails['data']['card']['card_id'];
		// console.log(employeeCardId);

		let bodyFormData = new FormData();
		bodyFormData.append('epoch', attendanceCreateObjTemp['epoch']);
		bodyFormData.append('type', attendanceCreateObjTemp['type']);
		bodyFormData.append('card_id', employeeCardId);
		bodyFormData.append('token', cookie['token']);

		let options = { headers: { "Authorization": cookie['token'] }, "Content-Type": "multipart/form-data" }
		axios
			.post(api_endpoint, bodyFormData, options)
			.then((response) => {
				setShowLoaderScreenFlag(false);
				getEmployeeTimesheet(employeeDetails['id'], selectedDate);
			})
			.catch(error => {
				console.log(error);
				setShowLoaderScreenFlag(false);
			})


	}

	function openMap(data) {
		console.log(data);
		setEmployeeLocationData(data);
		toggleMapLocationModal();
	}

	function toggleMapLocationModal() {
		if (mapLocationModalState === true) {
			setEmployeeLocationData({});
		}
		setMapLocationModalState(!mapLocationModalState);
	}

	function toggleEditAttendanceInput(index) {
		let timeSheetDataTemp = JSON.parse(JSON.stringify(timesheetData));
		if (timeSheetDataTemp[index]['updateFlag']) {
			timeSheetDataTemp[index]['updateFlag'] = false;
		}
		else {
			timeSheetDataTemp[index]['updateFlag'] = true;
		}
		setTimesheetData(JSON.parse(JSON.stringify(timeSheetDataTemp)));
	}

	function changeAttendanceTimestamp(index, e) {
		// console.log(index, e.target.value);
		let timeSheetDataTemp = JSON.parse(JSON.stringify(timesheetData));
		// console.log("Epoch Date = ", epochDate);
		let inputTimeString = e.target.value;
		let splitedTime = inputTimeString.split(':');
		let inputTimeHours = parseInt(splitedTime[0]);
		let inputTimeMinutes = parseInt(splitedTime[1]);
		let targetSecondsPassed = inputTimeHours * 3600 + inputTimeMinutes * 60;
		let newAttendanceEpoch = selectedDateEpoch + targetSecondsPassed;
		let formatHourMinuteFromNewEpoch = formatHourMinuteFromEpoch(newAttendanceEpoch);
		// console.log(formatHourMinuteFromNewEpoch);
		timeSheetDataTemp[index]['updatedEpoch'] = newAttendanceEpoch;
		timeSheetDataTemp[index]['timeHourMinuteString'] = formatHourMinuteFromNewEpoch;
		setTimesheetData(JSON.parse(JSON.stringify(timeSheetDataTemp)));
	}

	useEffect(() => {
		// let todayDateTemp = formatDate(new Date()).toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
		// setSelectedDateValues(todayDateTemp);

		return () => {

		}
	}, [])


	useEffect(() => {
		if (props.selectedEmployeeIndex > -1) {
			let employeeId = props.allEmployees[props.selectedEmployeeIndex]['id'];
			setEmployeeDetails(JSON.parse(JSON.stringify(props.allEmployees[props.selectedEmployeeIndex])));
			console.log(employeeId);
			getEmployeeTimesheet(employeeId, selectedDate);
		}

		return () => {

		}
	}, [props.selectedEmployeeIndex])

	useEffect(() => {
		if (props.selectedEmployeeIndex > -1) {
			let employeeId = props.allEmployees[props.selectedEmployeeIndex]['id'];
			getEmployeeTimesheet(employeeId, selectedDate);
		}

		return () => {

		}
	}, [selectedDate])

	return (
		<>
			<div className='EmployeeTimestampTableContainer'>
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<div className='row'>
							<div className='col-6'>
								<div className='dateSelectContainer'>
									<DatePicker wrapperClassName="monthYearPicker" dateFormat="dd/MM/yyyy" locale="pt" selected={selectedDate} onChange={(date) => selectDate(date)} />
								</div>
							</div>
							<div className='col-6'>
								<div className='d-flex flex-row-reverse addAttendanceContainer'>
									<button onClick={addAttendance}>Add Attendance</button>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-12'>
								{
									attendanceCreateFlag ? <>
										<div className='addAttendanceModal'>
											<div className='inputBox d-flex justify-content-between'>
												<select id="attendanceTypeInput" name="attendanceTypeInput" className='attendanceTypeInput' value={attendanceCreateObj['type']} onChange={(e) => updateAttendanceCreateObj(e, 'type')} >
													<option value="1">{translateBr("clock_in")}</option>
													<option value="0">{translateBr("clock_out")}</option>
												</select>
												<input type="time" value={attendanceCreateObj['timestring']} onChange={(e) => updateAttendanceCreateObj(e, 'timestamp')} className='attendanceTimeInput' />
											</div>
											<div className='actionBox d-flex justify-content-between'>
												<button className='saveButton' onClick={createEmployeeAttendance}>Save</button>
												<button className='cancelButton' onClick={cancelEmployeeAttendanceCreation}>Cancel</button>
											</div>
										</div>
									</> : <>
									</>
								}

							</div>
						</div>
						<br />
					</div>
					<div className='col-12'>
						<div className='timestampTableColumn'>
							<div className='timestampTableContainer'>
								<table className='timestampTable'>
									<colgroup>
										<col span="1" style={{ width: "30%" }} />
										<col span="2" style={{ width: "70%" }} />
									</colgroup>
									<thead>
										<tr>
											<th>{translateBr("activity")}</th>
											<th>{translateBr("timestamp")}</th>
										</tr>
									</thead>
									<tbody className='timestampTableBody'>
										{
											timesheetData.map((data, index) => {
												return (
													<tr key={index}>
														<td className='timesheetActivityText'>{data.type === 0 ? translateBr("clocked_out") : data.type === 1 ? translateBr("clocked_in") : data.type === 2 ? translateBr("on_leave") : data.type === 3 ? translateBr("unknown") : ''}</td>
														{
															data.updateFlag ? <>
																<td className='activityTimestampText'>
																	{/* {data['time_stamp']} */}
																	<input value={data['timeHourMinuteString']} onChange={(e) => changeAttendanceTimestamp(index, e)} type='time' step="60000" />
																	<button onClick={() => editAttendance(data)}>Save</button>
																	<button onClick={() => toggleEditAttendanceInput(index)}>Cancel</button>
																</td>
															</> : <>
																<td className='activityTimestampText'>
																	<p>{data['time_stamp']}</p>
																	{
																		data.location.lat ? <><button className='timestampMapButton' onClick={() => { openMap(data) }}>Open Map</button></> : <></>
																	}
																	<button onClick={() => toggleEditAttendanceInput(index)}>Edit</button>
																</td>
															</>
														}
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>

					</div>

				</div>

			</div>
			<MapLocationModal modalState={mapLocationModalState} locationData={employeeLocationData} toggleModal={toggleMapLocationModal} />
			<LoaderScreen showLoaderScreenFlag={showLoaderScreenFlag} />
		</>
	)
}

export default EmployeeTimestampTableContainer
